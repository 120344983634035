import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import {
  fetchAppointmentNote,
  updateAppointmentNote,
} from '../../../actions/sessions';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['blockquote'],
  ],
};

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'align',
];

const horizontalLine = {
  borderTop: '2px solid #c2adff',
  width: '25px',
  marginLeft: 0,
  marginTop: '10px',
};

class NotesBox extends Component {
  constructor() {
    super();
    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    const { appointmentNoteID } = this.props;

    if (appointmentNoteID) {
      this.props.fetchAppointmentNote(appointmentNoteID, response =>
        this.setState({ text: response.data.note }),
      );
    }
  }

  updateNote = _.debounce(
    value =>
      this.props.updateAppointmentNote(this.props.appointmentNoteID, {
        note: value,
      }),
    500,
  );

  handleChange = value => {
    this.setState({ text: value });
    this.updateNote(value);
  };

  render() {
    return (
      <div>
        <h1 style={{ fontSize: '20px' }}>
          <FormattedMessage defaultMessage="Notes" />
        </h1>
        <hr style={horizontalLine} />
        <p
          style={{
            fontSize: '14px',
            color: 'rgba(146, 180, 255, 0.65)',
            fontStyle: 'italic',
            paddingLeft: '12px',
          }}
        >
          <FormattedMessage defaultMessage="Your Notes will be automatically saved." />
        </p>
        <ReactQuill
          formats={formats}
          modules={modules}
          value={this.state.text}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

NotesBox.propTypes = {
  appointmentNoteID: PropTypes.number.isRequired,
  fetchAppointmentNote: PropTypes.func.isRequired,
  updateAppointmentNote: PropTypes.func.isRequired,
};

export default connect(null, { fetchAppointmentNote, updateAppointmentNote })(
  NotesBox,
);
