import React from 'react';
import PropTypes from 'prop-types';

const textAreaStyle = {
  fontFamily: 'Source Sans Pro',
  width: '100%',
  maxWidth: '600px',
  height: '200px',
  borderRadius: '6px',
  boxShadow: '0 0 10px 0 rgba(99, 140, 177, 0.1)',
  border: 'solid 1px rgba(46, 95, 202, 0.1)',
  backgroundColor: '#ffffff',
  outline: 'none',
  resize: 'none',
  padding: '10px',
  marginBottom: '35px',
};

function TextArea({ input, meta }) {
  const { error, touched } = meta;
  return (
    <div style={{ width: '100%' }}>
      <textarea {...input} style={textAreaStyle} />{' '}
      {error && touched && (
        <p className="onboarding-error" style={{ position: 'initial' }}>
          {error}
        </p>
      )}
    </div>
  );
}

TextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

TextArea.defaultProps = {
  input: {},
  meta: {},
};

export default TextArea;
