import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import localeMessage from '../localeMessage';

const providerStyle = {
  padding: '5px 20px',
};

const AudioDifficulties = ({ appointment, locale }) => (
  <div style={providerStyle}>
    <div className="alternate-phone">
      <p>
        {localeMessage('forAudio', locale)}
        <b>{_.get(appointment, 'twilio_toll_free_number')}</b>
        {localeMessage('withPin', locale)}
        <b>{_.get(appointment, 'twilio_voice_token')}</b>.
      </p>
    </div>
  </div>
);

AudioDifficulties.propTypes = {
  appointment: PropTypes.object,
  locale: PropTypes.string.isRequired,
};

AudioDifficulties.defaultProps = {
  appointment: {},
};

function mapStateToProps(state) {
  return {
    appointment: state.appointments.appointment,
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps, {})(AudioDifficulties);
