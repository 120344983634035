import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import Sun from '../../../../images/onboarding/sunny.svg';
import Moon from '../../../../images/onboarding/moon.svg';

const errorStyles = {
  color: '#f44336',
  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '0.1px',
};

const GeneralAvailability = ({ input, meta }) => {
  const { name, value, onChange } = input;
  const { touched, error } = meta;
  const availableTimes = [
    'weekdays_day',
    'weekdays_night',
    'weekends_day',
    'weekends_night',
  ];

  const handleChange = (event, checkboxValue) => {
    const newValue = [...value];

    if (event.target.checked) {
      newValue.push(checkboxValue);
    } else {
      newValue.splice(newValue.indexOf(checkboxValue), 1);
    }

    return onChange(newValue);
  };

  return (
    <div className="general-availability">
      <Sun />
      <Moon />
      <div className="general-availability-row">
        <span>
          <FormattedMessage defaultMessage="MON-FRI" />
        </span>
        {_.map(availableTimes.slice(0, 2), (time, i) => (
          <div key={i}>
            <input
              type="checkbox"
              name={`${name}[${i}]`}
              value={time}
              checked={value.indexOf(time) !== -1}
              onChange={event => handleChange(event, time)}
            />
            <div className="checkbox-svg" />
          </div>
        ))}
      </div>
      <div className="general-availability-row">
        <span>
          <FormattedMessage defaultMessage="SAT-SUN" />
        </span>
        {_.map(availableTimes.slice(2), (time, i) => (
          <div key={i}>
            <input
              type="checkbox"
              name={`${name}[${i}]`}
              value={time}
              checked={value.indexOf(time) !== -1}
              onChange={event => handleChange(event, time)}
            />
            <div className="checkbox-svg" />
          </div>
        ))}
      </div>
      <p style={errorStyles}>{touched ? error : ''}</p>
    </div>
  );
};

GeneralAvailability.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default GeneralAvailability;
