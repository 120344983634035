/* eslint no-console: 0 */
import _ from 'lodash';

export const nameParser = userApptObj => {
  if (_.isEmpty(userApptObj)) return '';

  return `${userApptObj.first_name} ${userApptObj.last_name}`;
};

export const determineIfProvider = roles => {
  if (_.find(roles, { provider_yn: true })) {
    return true;
  }
  return false;
};

// push them to a route depending on their role
export const pushThem = self => {
  self.props.history.push({
    pathname: '/dashboard',
  });
};

// sets session as completed
export const setCompleted = self => {
  self.props.updateAppointment(
    self.props.appointment.id,
    {
      status: 'completed',
      process_payment:
        self.props.appointment.payment_method === 'inkblot' &&
        !self.props.appointment.paid_yn,
    },
    () => {},
  );
};

export const setFailed = self => {
  self.props.updateAppointment(
    self.props.appointment.id,
    { status: 'failed' },
    () => {},
  );
};
