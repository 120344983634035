import { combineReducers } from 'redux';
import { createReducers } from '../ducksHelpers';
import { appointmentDetailsSettings } from './appointmentDetails';
import { inHouseCompletedAppointmentsSettings } from './inHouseCompletedAppointments';

const settingList = [
  appointmentDetailsSettings,
  inHouseCompletedAppointmentsSettings,
];

const billingReducer = combineReducers(createReducers(settingList));

export default billingReducer;
