import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const RightMessageBubble = props => (
  <div className="sidebar-message-me">
    <div style={{width: '100%'}}>
      <p className="sidebar-message-body">{props.body}</p>
    </div>
    <p className="sidebar-message-timestamp">
      {moment(props.timestamp).format('LT')}
    </p>
  </div>
);

RightMessageBubble.propTypes = {
  timestamp: PropTypes.object,
  body: PropTypes.string,
};

export default RightMessageBubble;
