import React from 'react';
import _ from 'lodash';
import Range from '../../global/RangeSlider';

const colors = ['violet', 'mint-green', 'light-blue', 'dark-blue'];

const renderPostAssessments = assessment => {
  const sortedMetrics = _.forEach(
    _.orderBy(assessment, ['assessment_metric_id'], ['asc']),
    (metric, i) => {
      metric.color = colors[i];
    },
  );

  return _.map(sortedMetrics, ({ assessment_metric, color, value, id }) => {
    return (
      <div style={{ marginBottom: '30px' }} key={assessment_metric.id}>
        <p style={{ marginBottom: '20px' }} className="input-range-name">
          {assessment_metric.name}
        </p>
        <div className="range-container">
          <div className="range-description-container">
            <p>{assessment_metric.min_description}</p>
          </div>
          <div
            style={{
              marginLeft: '22px',
              marginRight: '22px',
            }}
          >
            <Range
              assessment_id={id}
              maxValue={assessment_metric.max}
              minValue={assessment_metric.min}
              color={color}
              value={value}
            />
          </div>

          <div className="range-description-container">
            <p>{assessment_metric.max_description}</p>
          </div>
        </div>
      </div>
    );
  });
};

renderPostAssessments.propTypes = {};

export default renderPostAssessments;
