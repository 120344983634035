import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FlatButton from 'material-ui/FlatButton';
import GlobalCard from '../../../global/Card';

const UserCard = props => {
  const { countdown, user, disabled, partner } = props;
  const parseDegrees = degrees =>
    _.map(degrees, degree => degree.acronym).join(', ');

  const userAvatar = client => (
    <div style={{ marginBottom: 20 }}>
      <div>
        {_.get(client, 'avatar.url') ? (
          <img
            className="card-image"
            alt="avatar"
            src={`${client.avatar.url}`}
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <div
            style={{
              margin: '0 auto',
              backgroundColor: '#92b4ff',
            }}
            className="card-no-image"
          >
            <span
              style={{
                color: '#fff',
              }}
              className="card-time"
            ></span>
          </div>
        )}
      </div>
      <h3 className="card-title">{client.preferred_name_with_last_name}</h3>
    </div>
  );

  return (
    <GlobalCard style={{ margin: '0 auto' }}>
      {user && (
        <div>
          {userAvatar(user)}
          {partner && userAvatar(partner)}
          <p style={{ marginBottom: '60px' }} className="card-subtitle-italics">
            {parseDegrees(user.degrees)}
          </p>
          <h3 className="card-subtitle">
            <FormattedMessage defaultMessage="Time Til Session" />
          </h3>
          <h3 style={{ marginBottom: '40px' }} className="card-time">
            {countdown}
          </h3>
          <Link to={`/sessions/providers/video_call/${props.appointment_id}`}>
            <FlatButton
              className={
                disabled ? 'flat-button-light-green' : 'flat-button-green'
              }
              label={<FormattedMessage defaultMessage="Start Session" />}
            />
          </Link>
        </div>
      )}
    </GlobalCard>
  );
};

UserCard.propTypes = {
  countdown: PropTypes.string,
  user: PropTypes.object,
  disabled: PropTypes.bool,
  partner: PropTypes.object,
};

UserCard.defaultProps = {
  countdown: '',
  disabled: null,
  user: {
    first_name: '',
    last_name: '',
    avatar: { url: undefined },
    degrees: {},
  },
  partner: null,
};

export default UserCard;
