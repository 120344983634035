import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateWithLine = props => (
  <div className="date-with-line">
    <span className="date">{props.timestamp}</span>
    <hr className="date-line" />
  </div>
);

DateWithLine.propTypes = {
  timestamp: PropTypes.object,
};

export default DateWithLine;
