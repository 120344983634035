import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const RightMessageBubble = props => (
  <div className="message from-me">
    <p className="chat-message-body">{props.body}</p>
    <p className="message-timestamp">
      {moment(props.timestamp).format('LT')}
    </p>
  </div>
);

RightMessageBubble.propTypes = {
  timestamp: PropTypes.object,
  body: PropTypes.string,
};

export default RightMessageBubble;
