import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

const horizontalLine = {
  borderTop: '2px solid #c2adff',
  width: '25px',
  marginLeft: 0,
  marginTop: '10px',
};

class PreviousNotes extends Component {
  static propTypes = {};

  static defaultProps = {};

  renderAllAppointmentNotes = notes =>
    _.map(notes, ({ id, start_date: startDate, note }) => {
      return (
        <div key={id}>
          <h1 className="previousNotesTitle">
            {this.props.intl.formatDate(startDate, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: localStorage.getItem('current-timezone'),
            })}
          </h1>
          <div
            className="noteText"
            dangerouslySetInnerHTML={{ __html: note }}
          />
        </div>
      );
    });

  render() {
    const { allAppointmentNotes } = this.props;

    return (
      <div>
        <h1 style={{ fontSize: '20px' }}>
          <FormattedMessage defaultMessage="Previous Notes" />
        </h1>
        <hr style={horizontalLine} />
        <div style={{ overflow: 'auto', height: 455 }}>
          {allAppointmentNotes.length > 0
            ? this.renderAllAppointmentNotes(allAppointmentNotes)
            : ''}
        </div>
      </div>
    );
  }
}

PreviousNotes.propTypes = {
  allAppointmentNotes: PropTypes.array,
};

PreviousNotes.defaultProps = {
  allAppointmentNotes: [],
};

export default injectIntl(PreviousNotes);
