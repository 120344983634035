import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import BottomBar from '../BottomBar';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import {
  bachelorDegrees,
  mastersDegrees,
  phds,
  medical,
  diplomas,
} from '../helpers';
import TextArea from '../form/TextArea';
import { COUNTRY } from '../../../environment';
import {
  updateDegrees,
  updateAdditionalCertifications,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class ProfessionalDetails1 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your professional details." />
    );
    const degreeValues = {
      degrees: [
        ..._.get(values, 'bachelor', []),
        ..._.get(values, 'masters', []),
        ..._.get(values, 'phd', []),
        ..._.get(values, 'medical', []),
        ..._.get(values, 'diploma', []),
      ],
    };
    const additionalDegreeValues = {
      additional_degrees: {
        certifications:
          values.additional_degrees && values.additional_degrees.split(','),
      },
      type_of: 'degrees',
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating professional details..." />,
    );

    Promise.all([
      this.props.updateDegrees(degreeValues),
      this.props.updateAdditionalCertifications(additionalDegreeValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'professional details 1',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Professional Details" />
          </h1>
          <div className="sub-page-indicator">
            1/{COUNTRY === 'US' ? '4' : '3'}
          </div>
        </div>
        <h3 style={{ marginBottom: '35px' }}>
          <FormattedMessage defaultMessage="Highest Relevant Degree (select all that apply)" />
        </h3>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            label={formatMessage({ defaultMessage: 'Bachelor' })}
            name="bachelor"
            options={bachelorDegrees}
          />
        </div>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            label={formatMessage({ defaultMessage: 'Masters' })}
            name="masters"
            options={mastersDegrees}
          />
        </div>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            label={formatMessage({ defaultMessage: 'PhD' })}
            name="phd"
            options={phds}
          />
        </div>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            label={formatMessage({ defaultMessage: 'Medical' })}
            name="medical"
            options={medical}
          />
        </div>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            label={formatMessage({ defaultMessage: 'Diploma/Certificate' })}
            name="diploma"
            options={diplomas}
          />
        </div>
        <p className="onboarding-label">
          <FormattedMessage defaultMessage="Other Degrees/Qualifications (separate by comma)" />
        </p>
        <div className="input-row">
          <Field component={TextArea} name="additional_degrees" />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

ProfessionalDetails1.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  updateDegrees: PropTypes.func.isRequired,
  updateAdditionalCertifications: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

const validate = values => {
  const errors = {};

  if (
    _.isEmpty(values.bachelor) &&
    _.isEmpty(values.masters) &&
    _.isEmpty(values.diploma) &&
    _.isEmpty(values.medical) &&
    _.isEmpty(values.phd)
  ) {
    errors.diploma = (
      <FormattedMessage defaultMessage="Must select at least one highest relevant degree" />
    );
  }

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(
    connect(null, { updateDegrees, updateAdditionalCertifications })(
      ProfessionalDetails1,
    ),
  ),
);
