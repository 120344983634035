/**
 *
 * VerticalRadioButton
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const RadioIcon = props => (
  // eslint-disable-next-line react/prop-types
  <div className={props.checked ? 'vri-out-checked' : 'vri-out'}>
    <div className="vri-wrapper">
      <div className={props.checked ? 'vri-in-checked' : 'vri-in'} />
    </div>
  </div>
);

const RadioLabel = props => (
  // eslint-disable-next-line react/prop-types
  <div className={props.checked ? 'vrl-label-checked' : 'vri-label'}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.children}
  </div>
);

function VerticalRadioButton({ input, meta, options }) {
  const { value, onChange } = input;
  const { touched, error } = meta;

  return (
    <div className="vri-container">
      <div>
        {options.map(option => (
          <div
            className="vrb"
            onClick={() => onChange(option.value)}
            key={`vr-key-${option.value}`}
            role="presentation"
          >
            <RadioIcon checked={option.value.toString() === value.toString()} />
            <RadioLabel checked={option.value.toString() === value.toString()}>
              {option.label}
            </RadioLabel>
          </div>
        ))}
      </div>
      <div className="vri-error">{touched ? error : ''}</div>
    </div>
  );
}

VerticalRadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default VerticalRadioButton;
