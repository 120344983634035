import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AvailableServicesBody from '../../clients/client_profile/AvailableServicesBody';

const horizontalLine = {
  borderTop: '2px solid #c2adff',
  width: '25px',
  marginLeft: 0,
  marginTop: '10px',
};

const AvailableServices = props => {
  const { clientData } = props;

  return (
    <div style={{ textAlign: 'left' }}>
      <h1 style={{ fontSize: '20px' }}>
        <FormattedMessage defaultMessage="Available Services" />
      </h1>
      <hr style={horizontalLine} />
      <AvailableServicesBody client={clientData} />
    </div>
  );
};

AvailableServices.propTypes = { clientData: PropTypes.object.isRequired };

export default AvailableServices;
