import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import localeMessage from '../localeMessage';
import CameraIMG from './camera.png';
import MicrophoneIMG from './microphone.png';
import CameraOffIMG from './cameraOff.png';
import MicrophoneOffIMG from './microphoneOff.png';
import ReloadIMG from './reload.png';
import Countdown from './countdown';

class BottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: true,
      sessionOver: false,
    };
  }

  toggleTimer = () => {
    this.setState(prevState => ({
      timer: !prevState.timer,
    }));
  };

  sessionOver = () => {
    this.setState({ sessionOver: true });
  };

  render() {
    const {
      appointment,
      cameraOpen,
      audioOpen,
      connected,
      openDialog,
      user,
      locale,
    } = this.props;

    const { counsellor } = appointment;
    let partner = null;

    if (appointment.provider_type === 'couples') {
      if (user.id === appointment.couples_appointment_detail.host.id) {
        partner = appointment.couples_appointment_detail.guest;
      } else {
        partner = appointment.couples_appointment_detail.host;
      }
    }

    return (
      <div className="bottom-bar-container">
        <div className="bottom-bar-l">
          {!_.isEmpty(appointment) && (
            <Countdown
              connected={connected}
              appointment={appointment}
              sessionOver={this.sessionOver}
            />
          )}
          <div className="bottom-bar-name">
            <p className="name">
              {`${counsellor.first_name} ${counsellor.last_name}`}
            </p>
            <p className="role">{localeMessage('careProvider', locale)}</p>
          </div>
          {partner && (
            <div className="bottom-bar-name">
              <p className="name">
                {`${partner.first_name} ${partner.last_name}`}
              </p>
              <p className="role">{localeMessage('partner', locale)}</p>
            </div>
          )}
        </div>

        <div className="bottom-bar-r">
          <div className="camera">
            {cameraOpen ? (
              <img
                role="presentation"
                onClick={this.props.toggleCamera}
                src={CameraIMG}
                alt="camera"
              />
            ) : (
              <img
                role="presentation"
                onClick={this.props.toggleCamera}
                src={CameraOffIMG}
                alt="camera-off"
              />
            )}
          </div>
          <div className="mic">
            {audioOpen ? (
              <img
                role="presentation"
                src={MicrophoneIMG}
                alt="microphone"
                onClick={this.props.toggleMic}
              />
            ) : (
              <img
                role="presentation"
                src={MicrophoneOffIMG}
                alt="microphone"
                onClick={this.props.toggleMic}
              />
            )}
          </div>
          <div
            role="presentation"
            onClick={() => window.location.reload()}
            className="reload-container"
          >
            <img className="reload" src={ReloadIMG} alt="reload" />
            <p>{localeMessage('reconnect', locale)}</p>
          </div>
          <button
            className={
              this.state.sessionOver
                ? 'complete-button-over'
                : 'complete-button'
            }
            onClick={openDialog}
            type="button"
          >
            {localeMessage('completeSession', locale)}
          </button>
        </div>
      </div>
    );
  }
}

BottomBar.propTypes = {
  appointment: PropTypes.object,
  audioOpen: PropTypes.bool.isRequired,
  connected: PropTypes.bool.isRequired,
  cameraOpen: PropTypes.bool.isRequired,
  toggleCamera: PropTypes.func.isRequired,
  toggleMic: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  user: PropTypes.object,
  locale: PropTypes.string.isRequired,
};

BottomBar.defaultProps = {
  appointment: {},
  user: {},
};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(BottomBar);
