import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const LeftMessageBubble = props => (
  <div className="sidebar-message-you">
    <p className="sidebar-message-timestamp">
      {moment(props.timestamp).format('LT')}
    </p>
    <div style={{ width: '100%' }}>
      <p className="sidebar-message-body">{props.body}</p>
    </div>
  </div>
);

LeftMessageBubble.propTypes = {
  timestamp: PropTypes.object,
  body: PropTypes.string,
};

export default LeftMessageBubble;
