import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { COUNTRY, OTHER_COUNTRY_URL } from '../../../environment';
import BottomBar from '../BottomBar';
import CoffeeChat from '../../../../images/onboarding/practitioner/coffeechat.svg';
import { scrollToTop } from '../scroll';

class Introduction extends Component {
  componentDidMount() {
    scrollToTop();
  }

  renderCountrySwitch = () => {
    const canadian = (
      <FormattedMessage
        defaultMessage="You are on the Canadian version of Inkblot Practice. If you are an American Practitioner, <a>visit the American version of Inkblot Practice.</a>"
        values={{
          a: address => (
            <a href={`${OTHER_COUNTRY_URL}/signup`} className="link">
              {address}
            </a>
          ),
        }}
      />
    );

    const american = (
      <FormattedMessage
        defaultMessage="You are on the American version of Inkblot Practice. If you are an Canadian Practitioner, <a>visit the Canadian version of Inkblot Practice.</a>"
        values={{
          a: address => (
            <a href={`${OTHER_COUNTRY_URL}/signup`} className="link">
              {address}
            </a>
          ),
        }}
      />
    );

    return <p>{COUNTRY === 'CA' ? canadian : american}</p>;
  };

  render() {
    const email = this.props.intl.formatMessage({
      defaultMessage: 'practitionernetwork@inkblottherapy.com',
    });
    return (
      <div>
        <CoffeeChat className="coffee-chat" style={{ marginBottom: '40px' }} />
        <h1 style={{ marginBottom: '40px' }}>
          <FormattedMessage defaultMessage="Welcome to Inkblot Practice!" />
        </h1>
        {this.renderCountrySwitch()}
        <p>
          <FormattedMessage
            defaultMessage="Signing up to be part of the Inkblot practitioner network is free. You
          will be required to upload your professional license/registration,
          liability insurance, highest degree and provide details around your
          practice. A member of our clinical services team will contact you via
          email once you have completed the requirements."
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="You will also get to try our practice management solution for your own
          practice and clients, free for 30 days, no credit card required. There
          will be an option to subscribe after 30 days."
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="If you are only interested in accessing our practice management
          solution, you will have the option to skip the steps required to
          register as an Inkblot practitioner."
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="If you have any questions, please contact {link}."
            values={{
              link: (
                <a href={`mailto:${email}`}>
                  {email}
                </a>
              ),
            }}
          />
        </p>
        <BottomBar {...this.props} />
      </div>
    );
  }
}

Introduction.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Introduction);
