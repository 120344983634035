import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import axios from 'axios';
import { headers } from '../../../actions/defaults';
import { API_URL } from '../../../environment';
import FormSelector from './FormSelector';

class ClientSelector extends Component {
  constructor() {
    super();
    this.state = {
      isFetchCompleted: false,
      clients: [],
    };
  }

  componentDidMount() {
    const { providerType, withProviderType } = this.props;

    if (withProviderType && !providerType) {
      return;
    }

    this.updateOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerType !== prevProps.providerType) {
      this.updateOptions();
    }
  }

  updateOptions() {
    const { providerType } = this.props;

    let url = `${API_URL}/practice/clients/client_selector`;
    if (providerType) {
      url = `${url}?provider_type=${providerType}`;
    }

    this.setState({ clients: [] });
    axios
      .get(url, headers())
      .then(response => {
        this.setState({
          isFetchCompleted: true,
          clients: response.data,
        });
      })
      .catch(() => {});
  }

  render() {
    const { fieldName, hideTitle, title, ...rest } = this.props;

    const { clients } = this.state;

    const options = clients
      .map(client => ({
        label: client.full_name.replace('  ', ' '),
        value: client.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div className="client-selector">
        {hideTitle ? '' : <h6 className="shelf-form-title">{title}</h6>}
        <Field
          name={fieldName}
          isFetchCompleted={this.state.isFetchCompleted}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          options={options}
          component={FormSelector}
        />
      </div>
    );
  }
}

ClientSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  hideTitle: PropTypes.bool,
  providerType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withProviderType: PropTypes.bool,
  title: PropTypes.string,
};

ClientSelector.defaultProps = {
  hideTitle: false,
  providerType: null,
  withProviderType: false,
  title: 'Client',
};

export default ClientSelector;
