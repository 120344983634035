import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { injectIntl } from 'react-intl';
import GlobalDialog from '../global/Dialog';

const h3Style = {
  color: '#19336a',
  fontSize: '22px',
  fontFamily: "'Barlow', sans-serif",
  fontWeight: '800',
  marginBottom: '2px',
};

const pStyle = {
  fontSize: '18px',
  color: '#222747',
};

const ErrorDialog = ({ open, handleClose, message, intl }) => (
  <GlobalDialog
    modal={false}
    open={open}
    autoDetectWindowHeight={false}
    contentClassName="error-dialog"
    handleClose={handleClose}
  >
    <h3 style={h3Style}>
      {intl.formatMessage({ defaultMessage: 'An error occured' })}
    </h3>
    <div className="ed-content">
      <p style={pStyle}>{message}</p>
    </div>
    <div style={{ textAlign: 'center', marginTop: '40px' }}>
      <FlatButton
        label={intl.formatMessage({ defaultMessage: 'Try Again' })}
        className="rounded-button-teal"
        onClick={handleClose}
      />
    </div>
  </GlobalDialog>
);

ErrorDialog.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ErrorDialog);
