export const scrollToTop = () => {
  const panelContent = document.getElementById('panel-content');
  if (panelContent) panelContent.scrollTop = 0;
};

export const scrollDown = () => {
  const panelContent = document.getElementById('panel-content');
  if (panelContent) {
    const scrollAmount = Number.parseInt(panelContent.clientHeight, 10) - 150;
    panelContent.scrollBy(0, scrollAmount);
  }
};

export const scrollBarVisible = () => {
  const panelContent = document.getElementById('panel-content');
  if (!panelContent) return false;
  return panelContent.scrollHeight > panelContent.clientHeight;
};

export const isFullyScrolled = that => {
  const panelContent = document.getElementById('panel-content');
  if (panelContent) {
    that.setState({
      fullyScrolled:
        panelContent.scrollHeight - panelContent.scrollTop - 10 <= panelContent.clientHeight,
    });
  }
};
