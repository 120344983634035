import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import get from 'lodash/get';

import InkblotLogo from '../../../images/onboarding/practitioner/inkblot-logo.svg';
import Panel from './Panel';
import PersonalDetails from './pages/PersonalDetails';
import ThankYou from './pages/ThankYou';
import { fetchUser } from '../../actions/user';
import LoadingPanel from '../global/LoadingPanel';
import { scrollToTop } from './scroll';
import { COUNTRY } from '../../environment';
import AutomaticPayouts from './pages/AutomaticPayouts';
import ErrorDialog from './ErrorDialog';
import {
  PERSONAL_DETAILS,
  LOCATION_DETAILS,
  MATCHING_PREFERENCES,
  AUTOMATIC_PAYOUTS,
  THANK_YOU,
} from './data';
import { signoutUser } from '../../actions/auths';
import LocationDetails from './pages/LocationDetails';
import MatchingPreferences from './pages/MatchingPreferences';

class PracticeOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PERSONAL_DETAILS,
      subPage: 0,
      loadingPanel: {
        open: false,
        message: '',
      },
      error: {
        open: false,
        message: '',
      },
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      // this.redirect();
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { subPage, page } = this.state;

    if (subPage !== prevState.subPage || page !== prevState.page) {
      scrollToTop();
    }
  }

  setSubPage = (subPage = 0) => this.setState({ subPage });

  setPage = page => this.setState({ page });

  nextSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage + 1 }));

  previousSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage - 1 }));

  nextPage = () => this.setState(prevState => ({ page: prevState.page + 1 }));

  previousPage = () =>
    this.setState(prevState => ({ page: prevState.page - 1 }));

  openErrorDialog = message =>
    this.setState({ error: { open: true, message } });

  closeErrorDialog = () =>
    this.setState({ error: { open: false, message: '' } });

  openLoadingPanel = message => {
    scrollToTop();
    this.setState({ loadingPanel: { open: true, message } });
  };

  closeLoadingPanel = () =>
    this.setState({ loadingPanel: { open: false, message: '' } });

  skipToEnd = () => this.setPage(THANK_YOU);
  skipToNext = currentStep => this.setPage(currentStep + 1);

  sendToDashboard = () => {
    this.props.fetchUser(() => this.props.history.push('/dashboard'));
  };

  errorCallback = (error, message) => {
    this.closeLoadingPanel();
    this.openErrorDialog(message);
  };

  redirect = () => {
    this.openLoadingPanel(<FormattedMessage defaultMessage="Loading..." />);
    this.props.fetchUser(
      response => {
        const user = response.data;
        const urlParams = new URL(window.location.href).searchParams;

        this.closeLoadingPanel();
        if (user && urlParams.get('verify')) {
          this.setState({ page: this.determineRedirectPage(user) });
        }
      },
      () => {
        this.props.signoutUser();
        this.props.history.push('/signin');
      },
    );
  };

  determineRedirectPage = user => {
    const avatar = get(user, 'avatar', false);
    const providerBio = get(user, 'provider_bio.overview', false);
    const stripeSetup = get(user, 'stripe_setup', false);

    if (!avatar || !providerBio) return MATCHING_PREFERENCES;
    if (!stripeSetup) return AUTOMATIC_PAYOUTS;

    return MATCHING_PREFERENCES;
  };

  render() {
    const { page, loadingPanel, error } = this.state;
    const pageProps = {
      currentStep: page,
      nextPage: this.nextPage,
      previousPage: this.previousPage,
      openErrorDialog: this.openErrorDialog,
      openLoadingPanel: this.openLoadingPanel,
      closeLoadingPanel: this.closeLoadingPanel,
      errorCallback: this.errorCallback,
      skipToEnd: this.skipToEnd,
      skipToNext: this.skipToNext,
    };

    return (
      <div className="practitioner-onboarding-layout">
        <div className="light-background" />
        <div className="dark-background" />
        <a href={`https://${COUNTRY === 'US' ? 'us.' : ''}inkblotpractice.com`}>
          <div className="logo">
            <InkblotLogo />
            <FormattedMessage
              tagName="h3"
              defaultMessage="Specialized Practitioners"
            />
          </div>
        </a>
        <Panel currentStep={page}>
          {page === PERSONAL_DETAILS && <PersonalDetails {...pageProps} />}
          {page === LOCATION_DETAILS && <LocationDetails {...pageProps} />}
          {page === MATCHING_PREFERENCES && (
            <MatchingPreferences {...pageProps} />
          )}
          {page === AUTOMATIC_PAYOUTS && <AutomaticPayouts {...pageProps} />}
          {page === THANK_YOU && (
            <ThankYou
              currentStep={page}
              nextPage={this.sendToDashboard}
              nonFormPage
            />
          )}
          {loadingPanel.open && <LoadingPanel message={loadingPanel.message} />}
          <ErrorDialog
            open={error.open}
            handleClose={this.closeErrorDialog}
            message={error.message}
          />
        </Panel>
      </div>
    );
  }
}

PracticeOnboarding.propTypes = {
  ageGroups: PropTypes.array,
  authenticated: PropTypes.bool,
  designations: PropTypes.array,
  disorders: PropTypes.array,
  history: PropTypes.object.isRequired,
  languages: PropTypes.array,
  fetchUser: PropTypes.func.isRequired,
  insuranceCompanies: PropTypes.array,
  approachesToCare: PropTypes.array,
  signoutUser: PropTypes.func.isRequired,
};

PracticeOnboarding.defaultProps = {
  ageGroups: [],
  authenticated: false,
  designations: [],
  disorders: [],
  languages: [],
  insuranceCompanies: [],
  approachesToCare: [],
};

const mapStateToProps = state => ({
  ageGroups: state.misc.ageGroups,
  authenticated: state.auth.authenticated,
  designations: map(state.misc.designations, designation => ({
    value: designation.acronym,
    label: designation.name,
  })),
  disorders: state.misc.disorders,
  languages: state.misc.languages,
  insuranceCompanies: state.misc.insuranceCompanies,
  approachesToCare: map(state.misc.approachesToCare, approach => ({
    value: approach.locale_key,
    label: approach.name,
  })),
});

export default connect(mapStateToProps, {
  signoutUser,
  fetchUser,
})(PracticeOnboarding);
