import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import 'react-select/dist/react-select.css';
import Down from '../../../../images/onboarding/practitioner/down.svg';
import Search from '../../../../images/onboarding/practitioner/search.svg';

const menuContainerStyle = {
  border: 'none',
  marginTop: '10px',
};

const menuStyle = {
  borderRadius: '4px',
  boxShadow: '0 0 10px 0 rgba(99, 140, 177, 0.2)',
};

const style = {
  backgroundColor: 'rgba(240, 242, 245, 0.79)',
  border: 'none',
  borderRadius: '4px',
  height: '40px',
  boxShadow: 'none',
};

const wrapperStyle = {
  width: '200px',
};

const arrowRenderer = () => <Down />;
const searchRenderer = () => <Search className="search-icon" />;

function Dropdown({
  label,
  input,
  meta,
  options,
  clearable,
  searchable,
  multi,
  width,
  disabled,
}) {
  const { error, touched } = meta;
  const { onBlur, value } = input;

  return (
    <div style={{ position: 'relative' }}>
      <p className="onboarding-label">{label}</p>
      <Select
        {...input}
        arrowRenderer={searchable ? searchRenderer : arrowRenderer}
        clearable={clearable}
        searchable={searchable}
        menuContainerStyle={menuContainerStyle}
        menuStyle={menuStyle}
        onBlur={() => onBlur(value)}
        options={options}
        placeholder=""
        wrapperStyle={width ? { width } : wrapperStyle}
        style={style}
        multi={multi}
        disabled={disabled}
        noResultsText={<FormattedMessage defaultMessage="No results found" />}
      />
      {touched && error && <p className="onboarding-error">{error}</p>}
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  multi: PropTypes.bool,
  width: PropTypes.number,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: undefined,
  input: {},
  meta: {},
  options: [],
  clearable: false,
  searchable: false,
  multi: false,
  width: null,
  disabled: false,
};

export default Dropdown;
