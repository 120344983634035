import { combineReducers } from 'redux';
import { createReducers } from '../../ducksHelpers';
import { clientSessionNotesSettings } from './clientSessionNotes';
import { exportClientSessionNotesSettings } from './exportClientSessionNotes';

const settingList = [
  clientSessionNotesSettings,
  exportClientSessionNotesSettings,
];

const clientSessionNotesReducer = combineReducers(createReducers(settingList));

export default clientSessionNotesReducer;
