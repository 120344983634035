import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import localeMessage from '../../localeMessage';
import Send from '../../../../../../images/sessions/send.svg';

class MessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidMount() {
    document
      .getElementById('sidebar-message-input')
      .addEventListener('keypress', this.handleEnter, false);
  }

  componentWillUnmount() {
    document
      .getElementById('sidebar-message-input')
      .removeEventListener('keypress', this.handleEnter, false);
  }

  handleChange = event => this.setState({ message: event.target.value });

  handleEnter = event => {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { connected, handleNewMessage } = this.props;
    const { message } = this.state;

    if (connected && message !== '') {
      handleNewMessage(message);
      this.setState({ message: '' });
    }
  };

  render() {
    const { message } = this.state;
    const { locale } = this.props;

    return (
      <form className="sidebar-form" name="new_message">
        <div className="sidebar-input-container">
          <textarea
            className="sidebar-message-input"
            id="sidebar-message-input"
            rows="4"
            onChange={this.handleChange}
            placeholder={localeMessage('typeMessage', locale)}
            value={message}
          />
          <div
            className="sidebar-message-send sidebar-pointer"
            onClick={this.handleSubmit}
            role="presentation"
          >
            <Send />
          </div>
        </div>
      </form>
    );
  }
}

MessageForm.propTypes = {
  connected: PropTypes.bool.isRequired,
  handleNewMessage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

MessageForm.defaultProps = {};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(MessageForm);
