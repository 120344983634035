import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import FilesToClients from './FilesToClients';
import FilesFromClients from './FilesFromClients';
import FilesToAllClients from './FilesToAllClients';

const SharedFilesRoutes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/files_from_clients`}
      component={FilesFromClients}
    />
    <Route path={`${match.url}/files_to_clients`} component={FilesToClients} />
    <Route
      path={`${match.url}/files_to_all_clients`}
      component={FilesToAllClients}
    />
    <Route path={`${match.url}/`} component={FilesFromClients} />
  </Switch>
);

SharedFilesRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SharedFilesRoutes;
