import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const failedPaymentsSettings = {
  stateName: 'failedPayments',
  dataType: 'object',
  actions: {
    fetch: 'ducks/failed_payments/FETCH_FAILED_PAYMENTS',
  },
};

export function fetchFailedPayments(
  page = 1,
  filters,
  callback = () => {},
  errorCallback = () => {},
) {
  const filterString = Object.keys(filters).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`).join('&');

  return ducksActionCreator(
    failedPaymentsSettings.actions.fetch,
    'get',
    `${API_URL}/admin/card_payment_retries?page=${page}&${filterString}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
