import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { yesNo } from '../helpers';
import Input from '../form/Input';
import BottomBar from '../BottomBar';
import Dropdown from '../form/Dropdown';
import RadioButton from '../form/RadioButton';
import { COUNTRY } from '../../../environment';
import {
  createGoverningBodies,
  fetchSupervision,
  updateSupervision,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

const propTypes = {
  closeLoadingPanel: PropTypes.func.isRequired,
  createGoverningBodies: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  errorCallback: PropTypes.func.isRequired,
  fetchSupervision: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  skipToEnd: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  updateSupervision: PropTypes.func.isRequired,
};

class ProfessionalDetails3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldNumber: 1,
      supervision_yn: null,
    };
  }

  componentDidMount() {
    this.props.fetchSupervision(resp => {
      const { clinical_supervision: clinicalSupervision } = resp.data;
      this.setState({
        supervision_yn: !_.isEmpty(clinicalSupervision),
      });
    });
  }

  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your professional details." />
    );
    const governingBodies = [];
    _.each(Object.keys(values), field => {
      if (field.includes('governing_jurisdiction_')) {
        const i = field.substr(field.lastIndexOf('_') + 1, field.length);

        governingBodies.push({
          state: values[field],
          governing_body: values[`governing_body_${i}`],
          license_number: values[`license_number_${i}`],
        });
      }
    });
    const governingBodyValues = {
      governing_bodies: governingBodies,
    };

    const { supervision, receiptText } = values.clinical_supervision;
    const supervisionValues = {
      supervision,
      info_receipts_yn: true,
      receipt_text: receiptText,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating professional details..." />,
    );
    Promise.all([
      this.props.createGoverningBodies(governingBodyValues),
      this.props.updateSupervision(supervisionValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'professional details 3',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  addField = () => {
    this.setState(prevState => ({ fieldNumber: prevState.fieldNumber + 1 }));
  };

  renderInputs = () => {
    const { fieldNumber } = this.state;
    const inputs = _.times(fieldNumber, i => (
      <div
        className="input-row"
        key={i}
        style={{ alignItems: 'center', marginBottom: '40px' }}
      >
        <Field
          component={Dropdown}
          label={<FormattedMessage defaultMessage="Governing Jurisdiction" />}
          name={`governing_jurisdiction_${i}`}
          options={this.props.states}
        />
        <Field
          component={Input}
          label={<FormattedMessage defaultMessage="Governing Body" />}
          name={`governing_body_${i}`}
        />
        <Field
          component={Input}
          label={
            <FormattedMessage defaultMessage="License/Registration Number" />
          }
          name={`license_number_${i}`}
        />
        {fieldNumber - 1 === i && (
          <button
            className="add-more-button"
            type="button"
            onClick={this.addField}
          >
            +
          </button>
        )}
      </div>
    ));

    return inputs;
  };

  renderClinicalSupervision = () => (
    <div
      className="input-row"
      style={{ flexDirection: 'column', marginBottom: '40px' }}
    >
      <h3>
        <FormattedMessage defaultMessage="Are you practicing under a clinical supervisor?" />
      </h3>
      <Field
        name="clinical_supervision.supervision"
        options={yesNo}
        component={RadioButton}
        onChange={e =>
          this.setState({ supervision_yn: JSON.parse(e.target.value) })
        }
      />
      {this.state.supervision_yn && (
        <div>
          <h6>
            <FormattedMessage
              defaultMessage="Please enter the clinical supervision information (e.g. supervisor
            name and college number) as it should appear on session receipts for
            your clients who request it."
            />
          </h6>
          <Field
            className="onboarding-text"
            name="clinical_supervision.receipt_text"
            component={Input}
            style={{ marginBottom: 40, width: 220 }}
          />
        </div>
      )}
    </div>
  );

  render() {
    const { handleSubmit } = this.props;
    const { currentStep, previousPage, nextPage, skipToEnd } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Professional Details" />
          </h1>
          <div className="sub-page-indicator">
            3/{COUNTRY === 'US' ? '4' : '3'}
          </div>
        </div>
        <h3 style={{ marginBottom: '25px' }}>
          <FormattedMessage
            defaultMessage="Which {provinceOrState} are you licensed/registered with? Please provide the corresponding governing body and license/registration number."
            values={{
              provinceOrState: COUNTRY === 'US' ? 'state(s)' : 'province(s)',
            }}
          />
        </h3>
        {this.renderInputs()}
        {this.renderClinicalSupervision()}
        <BottomBar
          currentStep={currentStep}
          previousPage={previousPage}
          nextPage={nextPage}
          skipToEnd={skipToEnd}
        />
      </form>
    );
  }
}

ProfessionalDetails3.propTypes = propTypes;

const validate = values => {
  const errors = {};

  _.each(Object.keys(values), field => {
    if (field.includes('governing_jurisdiction_')) {
      const int = field.substr(field.lastIndexOf('_') + 1, field.length);
      if (values[field] && !values[`license_number_${int}`]) {
        errors[`license_number_${int}`] = (
          <FormattedMessage defaultMessage="Required" />
        );
      }
      if (values[field] && !values[`governing_body_${int}`]) {
        errors[`governing_body_${int}`] = (
          <FormattedMessage defaultMessage="Required" />
        );
      }
    }
  });

  if (!_.get(values.clinical_supervision, 'supervision')) {
    errors.clinical_supervision = {
      supervision: (
        <FormattedMessage defaultMessage="Please indicate whether or not you are practicing under a clinical supervisor" />
      ),
    };
  }
  if (
    _.get(values.clinical_supervision, 'supervision') &&
    !_.get(values.clinical_supervision, 'receipt_text')
  ) {
    errors.clinical_supervision = {
      receipt_text: (
        <FormattedMessage defaultMessage="Please enter what you should appear on client's receipts." />
      ),
    };
  }

  return errors;
};

const mapStateToProps = state => ({
  states: state.misc.states,
});

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(
  connect(mapStateToProps, {
    createGoverningBodies,
    fetchSupervision,
    updateSupervision,
  })(ProfessionalDetails3),
);
