import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';

import InkblotLogo from '../../../images/onboarding/practitioner/inkblot-logo.svg';
import {
  fetchDesignations,
  fetchLanguages,
  fetchAgeGroups,
  fetchInsuranceCompanies,
  fetchApproachToCares,
  fetchTherapeuticOrientations,
  fetchPronouns,
  fetchDiagnosisTypes,
  fetchSpecialities,
  fetchReligions,
  fetchGenderIdentities,
  fetchRaces,
  fetchStates,
} from '../../actions/misc';
import Panel from './Panel';
import Introduction from './pages/Introduction';
import PersonalDetails from './pages/PersonalDetails';
import LocationDetails from './pages/LocationDetails';
import ProfessionalDetails1 from './pages/ProfessionalDetails1';
import ProfessionalDetails2 from './pages/ProfessionalDetails2';
import ProfessionalDetails3 from './pages/ProfessionalDetails3';
import ProfessionalDetails4 from './pages/ProfessionalDetails4';
import PracticeDetails1 from './pages/PracticeDetails1';
import PracticeDetails2 from './pages/PracticeDetails2';
import PracticeDetails3 from './pages/PracticeDetails3';
import PracticeDetails4 from './pages/PracticeDetails4';
import PracticeDetails5 from './pages/PracticeDetails5';
import PracticeDetails6 from './pages/PracticeDetails6';
import MatchingPreferences from './pages/MatchingPreferences';
import ThankYou from './pages/ThankYou';
import TermsOfUse from './pages/TermsOfUse';
import { fetchUser } from '../../actions/user';
import LoadingPanel from '../global/LoadingPanel';
import { scrollToTop } from './scroll';
import { COUNTRY } from '../../environment';
import CertificateUpload from './pages/CertificateUpload';
import AutomaticPayouts from './pages/AutomaticPayouts';
import ErrorDialog from './ErrorDialog';
import {
  INTRODUCTION,
  PERSONAL_DETAILS,
  LOCATION_DETAILS,
  PROFESSIONAL_DETAILS,
  PRACTICE_DETAILS,
  MATCHING_PREFERENCES,
  TERMS_OF_USE,
  CERTIFICATE_UPLOAD,
  AUTOMATIC_PAYOUTS,
  THANK_YOU,
} from './helpers';
import { signoutUser } from '../../actions/auths';
import { logEvent, SKIP_REGISTRATION } from '../../utils/amplitude';

class PracticeOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: INTRODUCTION,
      subPage: 0,
      loadingPanel: {
        open: false,
        message: '',
      },
      error: {
        open: false,
        message: '',
      },
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.redirect();
    }
    this.props.fetchAgeGroups();
    this.props.fetchDesignations();
    this.props.fetchLanguages();
    this.props.fetchInsuranceCompanies();
    this.props.fetchApproachToCares();
    this.props.fetchTherapeuticOrientations();
    this.props.fetchPronouns();
    this.props.fetchDiagnosisTypes();
    this.props.fetchSpecialities();
    this.props.fetchReligions();
    this.props.fetchGenderIdentities();
    this.props.fetchRaces();
    this.props.fetchStates(COUNTRY === 'CA' ? 'CAN' : 'USA');
  }

  componentDidUpdate(_prevProps, prevState) {
    const { subPage, page } = this.state;

    if (subPage !== prevState.subPage || page !== prevState.page) {
      scrollToTop();
    }
  }

  setSubPage = (subPage = 0) => this.setState({ subPage });

  setPage = page => this.setState({ page });

  nextSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage + 1 }));

  previousSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage - 1 }));

  nextPage = () => this.setState(prevState => ({ page: prevState.page + 1 }));

  previousPage = () =>
    this.setState(prevState => ({ page: prevState.page - 1 }));

  openErrorDialog = message =>
    this.setState({ error: { open: true, message } });

  closeErrorDialog = () =>
    this.setState({ error: { open: false, message: '' } });

  openLoadingPanel = message => {
    scrollToTop();
    this.setState({ loadingPanel: { open: true, message } });
  };

  closeLoadingPanel = () =>
    this.setState({ loadingPanel: { open: false, message: '' } });

  skipToEnd = () => {
    logEvent(SKIP_REGISTRATION, {
      page: this.state.page,
    });
    this.setPage(THANK_YOU);
  };

  sendToDashboard = () => {
    this.props.fetchUser(() => this.props.history.push('/dashboard'));
  };

  errorCallback = (error, message) => {
    this.closeLoadingPanel();
    this.openErrorDialog(message);
  };

  redirect = () => {
    this.openLoadingPanel(
      this.props.intl.formatMessage({ defaultMessage: 'Loading...' }),
    );
    this.props.fetchUser(
      response => {
        const user = response.data;
        const urlParams = new URL(window.location.href).searchParams;

        this.closeLoadingPanel();
        if (user && urlParams.get('verify')) {
          this.setState({ page: this.determineRedirectPage(user) });
        } else {
          this.setState({ page: LOCATION_DETAILS });
        }
      },
      () => {
        this.props.signoutUser();
        this.props.history.push('/signin');
      },
    );
  };

  determineRedirectPage = user => {
    const avatar = get(user, 'avatar', false);
    const providerBio = get(user, 'provider_bio.overview', false);
    const counsellorDocuments = get(user, 'counsellor_documents', []);
    const stripeSetup = get(user, 'stripe_setup', false);

    if (!avatar || !providerBio) return MATCHING_PREFERENCES;
    if (counsellorDocuments.length === 0) return CERTIFICATE_UPLOAD;
    if (!stripeSetup) return AUTOMATIC_PAYOUTS;

    return MATCHING_PREFERENCES;
  };

  render() {
    const { page, subPage, loadingPanel, error } = this.state;
    const {
      ageGroups,
      designations,
      languages,
      insuranceCompanies,
    } = this.props;
    const pageProps = {
      currentStep: page,
      currentSubStep: subPage,
      nextPage: this.nextPage,
      previousPage: this.previousPage,
      openErrorDialog: this.openErrorDialog,
      openLoadingPanel: this.openLoadingPanel,
      closeLoadingPanel: this.closeLoadingPanel,
      errorCallback: this.errorCallback,
      skipToEnd: this.skipToEnd,
    };
    const firstSubPageProps = {
      ...pageProps,
      nextPage: this.nextSubPage,
    };
    const middleSubPageProps = {
      ...pageProps,
      nextPage: this.nextSubPage,
      previousPage: this.previousSubPage,
    };
    const endSubPageProps = {
      ...pageProps,
      nextPage: () => {
        this.nextPage();
        this.setSubPage();
      },
      previousPage: this.previousSubPage,
    };

    return (
      <div className="practitioner-onboarding-layout">
        <div className="light-background" />
        <div className="dark-background" />
        <a href={`https://${COUNTRY === 'US' ? 'us.' : ''}inkblotpractice.com`}>
          <div className="logo">
            <InkblotLogo />
            <h3>Inkblot Practice</h3>
          </div>
        </a>
        <Panel currentStep={page}>
          {page === INTRODUCTION && <Introduction {...pageProps} nonFormPage />}
          {page === PERSONAL_DETAILS && <PersonalDetails {...pageProps} />}
          {page === LOCATION_DETAILS && <LocationDetails {...pageProps} />}
          {page === TERMS_OF_USE && <TermsOfUse {...pageProps} />}
          {page === PROFESSIONAL_DETAILS && subPage === 0 && (
            <ProfessionalDetails1 {...firstSubPageProps} />
          )}
          {page === PROFESSIONAL_DETAILS && subPage === 1 && (
            <ProfessionalDetails2
              {...middleSubPageProps}
              designations={designations}
            />
          )}
          {page === PROFESSIONAL_DETAILS && subPage === 2 && (
            <ProfessionalDetails3
              {...middleSubPageProps}
              nextPage={() => {
                if (COUNTRY === 'US') {
                  this.setSubPage(3);
                } else {
                  this.nextPage();
                  this.setSubPage();
                }
              }}
            />
          )}
          {page === PROFESSIONAL_DETAILS && subPage === 3 && (
            <ProfessionalDetails4
              {...endSubPageProps}
              insuranceCompanies={insuranceCompanies}
            />
          )}
          {page === PRACTICE_DETAILS && subPage === 0 && (
            <PracticeDetails1
              {...firstSubPageProps}
              languages={languages}
              previousPage={() => {
                this.previousPage();
                if (COUNTRY === 'US') {
                  this.setSubPage(3);
                } else {
                  this.setSubPage(2);
                }
              }}
            />
          )}
          {page === PRACTICE_DETAILS && subPage === 1 && (
            <PracticeDetails2 {...middleSubPageProps} ageGroups={ageGroups} />
          )}
          {page === PRACTICE_DETAILS && subPage === 2 && (
            <PracticeDetails3 {...middleSubPageProps} />
          )}
          {page === PRACTICE_DETAILS && subPage === 3 && (
            <PracticeDetails4 {...middleSubPageProps} />
          )}
          {page === PRACTICE_DETAILS && subPage === 4 && (
            <PracticeDetails5 {...middleSubPageProps} ageGroups={ageGroups} />
          )}
          {page === PRACTICE_DETAILS && subPage === 5 && (
            <PracticeDetails6 {...endSubPageProps} />
          )}
          {page === MATCHING_PREFERENCES && (
            <MatchingPreferences
              {...pageProps}
              previousPage={() => {
                this.previousPage();
                this.setSubPage(5);
              }}
            />
          )}
          {page === CERTIFICATE_UPLOAD && <CertificateUpload {...pageProps} />}
          {page === AUTOMATIC_PAYOUTS && <AutomaticPayouts {...pageProps} />}
          {page === THANK_YOU && (
            <ThankYou
              currentStep={page}
              nextPage={this.sendToDashboard}
              nonFormPage
            />
          )}
          {loadingPanel.open && <LoadingPanel message={loadingPanel.message} />}
          <ErrorDialog
            open={error.open}
            handleClose={this.closeErrorDialog}
            message={error.message}
          />
        </Panel>
      </div>
    );
  }
}

PracticeOnboarding.propTypes = {
  ageGroups: PropTypes.array,
  authenticated: PropTypes.bool,
  designations: PropTypes.array,
  fetchAgeGroups: PropTypes.func.isRequired,
  fetchApproachToCares: PropTypes.func.isRequired,
  fetchTherapeuticOrientations: PropTypes.func.isRequired,
  fetchDesignations: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchPronouns: PropTypes.func.isRequired,
  fetchDiagnosisTypes: PropTypes.func.isRequired,
  fetchSpecialities: PropTypes.func.isRequired,
  fetchReligions: PropTypes.func.isRequired,
  fetchGenderIdentities: PropTypes.func.isRequired,
  fetchRaces: PropTypes.func.isRequired,
  fetchStates: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  languages: PropTypes.array,
  fetchUser: PropTypes.func.isRequired,
  fetchInsuranceCompanies: PropTypes.func.isRequired,
  insuranceCompanies: PropTypes.array,
  signoutUser: PropTypes.func.isRequired,
};

PracticeOnboarding.defaultProps = {
  ageGroups: [],
  authenticated: false,
  designations: [],
  languages: [],
  insuranceCompanies: [],
};

const mapStateToProps = state => ({
  ageGroups: state.misc.ageGroups,
  authenticated: state.auth.authenticated,
  designations: map(state.misc.designations, designation => ({
    value: designation.acronym,
    label: designation.name,
  })),
  languages: state.misc.languages,
  insuranceCompanies: state.misc.insuranceCompanies,
});

export default injectIntl(
  connect(mapStateToProps, {
    fetchAgeGroups,
    fetchApproachToCares,
    fetchTherapeuticOrientations,
    fetchDesignations,
    fetchLanguages,
    fetchPronouns,
    fetchDiagnosisTypes,
    fetchSpecialities,
    fetchReligions,
    fetchGenderIdentities,
    fetchRaces,
    fetchStates,
    fetchUser,
    fetchInsuranceCompanies,
    signoutUser,
  })(PracticeOnboarding),
);
