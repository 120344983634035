/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Client } from '@twilio/conversations';

import {
  fetchTwilioTokenWithChatId,
  fetchTwilioToken,
  sendMessage,
  markAsRead,
  fetchCouplesGuestChatToken,
} from '../../../../../actions/messaging';
import Messages from './Messages';
import MessageForm from './MessageForm';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      channel: null,
      chat: null,
      isCouplesGuest: false,
    };
  }

  componentDidMount() {
    const { appointment, user } = this.props;
    this._isMounted = true;

    if (appointment.id && user.id) {
      if (
        appointment.provider_type === 'couples' &&
        user.id === appointment.couples_appointment_detail.guest.id
      ) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ isCouplesGuest: true }, () =>
          this.initCouplesGuestChat(),
        );
      } else {
        this.initChats();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setupChannel = channel => {
    channel.getMessages().then(messages => {
      const totalMessages = messages.items.length;
      for (let message = 0; message < totalMessages; message += 1) {
        this.addMessage(messages.items[message].state, false);
      }
    });

    channel.on('messageAdded', ({ author, body, dateCreated }) => {
      this.addMessage({ author, body, timestamp: dateCreated }, true);
    });
  };

  initCouplesGuestChat = () => {
    const { appointment } = this.props;

    this.props.fetchCouplesGuestChatToken(appointment.id, (token, error) => {
      if (!error) {
        const chatClient = new Client(token.id);
        chatClient.on('stateChanged', state => {
          if (state === 'initialized') {
            chatClient
              .getConversationBySid(token.channel_id)
              .then(channel => {
                if (this._isMounted) {
                  this.setState({ channel });
                }
                this.setupChannel(channel);
              })
              .catch(() => {
                // eslint-disable-next-line no-console
                console.log(`Failed to find chat with id: ${token.channel_id}`);
              });
          }
        });
      }
    });
  };

  initChats = () => {
    const {
      appointment: { chat },
    } = this.props;
    const chatId = chat.id;

    this.props.fetchTwilioTokenWithChatId(chatId, tokenResponse => {
      const token = tokenResponse.data;
      const chatClient = new Client(token.id);
      chatClient.on('stateChanged', state => {
        if (state === 'initialized') {
          this.rejoinChannel(chatClient);
        }
      });
    });
  };

  rejoinChannel = chatClient => {
    const {
      appointment: { chat },
    } = this.props;

    this.setState({ chat }, () => this.props.markAsRead({ id: chat.id }));
    const promise = chatClient.getConversationBySid(chat.channel);
    promise
      .then(channel => {
        this.setState({ channel });
        this.setupChannel(channel);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  addMessage = (message, mark = false) => {
    const { isCouplesGuest } = this.state;
    if (this._isMounted) {
      const { token } = this.props;
      const messageData = { ...message, me: message.author === token.identity };

      this.setState(
        {
          // eslint-disable-next-line react/no-access-state-in-setstate
          messages: [...this.state.messages, messageData],
        },
        () => {
          if (!isCouplesGuest && !messageData.me && mark) {
            this.props.markAsRead({ id: this.state.chat.id });
          }
        },
      );
    }
  };

  handleNewMessage = text => {
    const { channel, chat, isCouplesGuest } = this.state;

    if (channel) {
      if (!isCouplesGuest) {
        this.props.sendMessage({
          id: chat.id,
          message: text,
          sensitive_yn: true,
        });
      }

      channel.sendMessage(text);
    }
  };

  render() {
    const { channel, messages, isCouplesGuest } = this.state;
    const { appointment, timezone } = this.props;

    const receivers = [];

    if (appointment.provider_type === 'couples') {
      receivers.push(appointment.couples_appointment_detail.provider);
      if (isCouplesGuest) {
        receivers.push(appointment.couples_appointment_detail.host);
      } else {
        receivers.push(appointment.couples_appointment_detail.guest);
      }
    } else {
      receivers.push(appointment.counsellor);
    }

    return (
      <div className="sidebar-chat-container sidebar-flex">
        <Messages messages={messages} timezone={timezone} />
        <MessageForm
          connected={channel !== null}
          handleNewMessage={this.handleNewMessage}
        />
      </div>
    );
  }
}

Chat.propTypes = {
  appointment: PropTypes.object,
  fetchTwilioTokenWithChatId: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  token: PropTypes.object,
  user: PropTypes.object,
  fetchCouplesGuestChatToken: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  appointment: {},
  token: {},
  user: {},
};

function mapStateToProps(state) {
  return {
    user: state.user,
    token: state.misc.token,
    chats: state.chats,
    timezone: _.get(state, 'user.preference.timezone', 'America/Toronto'),
    appointment: state.session.appointment,
  };
}

export default connect(mapStateToProps, {
  fetchTwilioTokenWithChatId,
  fetchTwilioToken,
  sendMessage,
  markAsRead,
  fetchCouplesGuestChatToken,
})(Chat);
