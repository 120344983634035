import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import Dropdown from '../form/Dropdown';
import Input from '../form/Input';
import BottomBar from '../BottomBar';
import { yesNo, referrals } from '../helpers';
import { timezones } from '../../../utils/timezone';
import Checkbox from '../form/Checkbox';
import normalizePhone from '../../../utils/normalizePhone';
import { COUNTRY } from '../../../environment';
import RadioButton from '../form/RadioButton';
import {
  updatePersonalDetails,
  updatePreferences,
  updateProviderDetails,
  updatePhoneNumbers,
  updateAddress,
  updatePracticeDetails,
} from '../../../actions/preferences';
import {
  canadianPostal,
  usPostal,
  normalizeUSZip,
  normalizeCanadianPostal,
} from '../../global/validation';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class LocationDetails extends Component {
  componentDidMount() {
    this.props.change(
      'country',
      COUNTRY === 'US'
        ? {
            value: 'USA',
            label: <FormattedMessage defaultMessage="United States" />,
          }
        : { value: 'CAN', label: <FormattedMessage defaultMessage="Canada" /> },
    );
  }

  onSubmit = values => {
    const { otherReferral, currentStep } = this.props;
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your location details." />
    );
    const personalDetailValues = {
      personal_detail: {
        referral_source: otherReferral
          ? values.other_referral
          : _.get(values, 'referral_source.value'),
      },
    };
    const prefernceValues = {
      preference: {
        timezone: _.get(values, 'timezone.value'),
        sms_reminder_yn: !_.isEmpty(values.sms_reminder),
      },
    };
    const providerDetailValues = {
      provider_detail: {
        practice_name: values.practice_name,
        custom_tax_yn: values.custom_tax_yn,
      },
    };
    const practiceDetailValues = {
      practice_detail: {
        business_email: values.business_email,
        url: values.url,
        practice_name: values.practice_name,
      },
    };
    const phoneNumberValues = {
      mobile_phone: values.sms_number,
      work_phone: values.office_number,
      fax_phone: values.fax_number,
    };
    const addressValues = {
      address: {
        street: values.street,
        alternate_street: values.alternate_street,
        city: values.city,
        code: values.code,
        state_code: _.get(values, 'state_code.value'),
      },
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating location details" />,
    );
    Promise.all([
      this.props.updatePersonalDetails(personalDetailValues),
      this.props.updatePreferences(prefernceValues),
      this.props.updateProviderDetails(providerDetailValues),
      this.props.updatePhoneNumbers(phoneNumberValues),
      this.props.updateAddress(addressValues),
      this.props.updatePracticeDetails(practiceDetailValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, { page: 'location details' });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit, otherReferral, intl } = this.props;
    const { formatMessage } = intl;
    // const options = COUNTRY === 'US' ? states : provinces;
    const options = this.props.states;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title" style={{ marginBottom: '20px' }}>
          <FormattedMessage tagName="h1" defaultMessage="Location Details" />
        </div>
        <h4 style={{ marginBottom: '40px' }}>
          <FormattedMessage
            defaultMessage="Only your {provinceOrState} and country will
          be visible to your clients, unless you indicate that you provide
          in-person sessions."
            values={{
              provinceOrState: COUNTRY === 'US' ? 'state' : 'province',
            }}
          />
        </h4>
        <div className="input-row">
          <Field
            component={Input}
            label={`${formatMessage({
              defaultMessage: 'Street 1',
            })} *`}
            name="street"
          />
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="Street 2" />}
            name="alternate_street"
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Unit, Suite, Apt number',
            })}
          />
          <Field
            component={Input}
            label={`${formatMessage({
              defaultMessage: 'City/Town',
            })} *`}
            name="city"
          />
        </div>
        <div className="input-row">
          <Field
            component={Dropdown}
            label={`${formatMessage({
              defaultMessage: 'Country',
            })} *`}
            name="country"
            options={
              COUNTRY === 'US'
                ? [
                    {
                      value: 'USA',
                      label: (
                        <FormattedMessage defaultMessage="United States" />
                      ),
                    },
                  ]
                : [
                    {
                      value: 'CAN',
                      label: <FormattedMessage defaultMessage="Canada" />,
                    },
                  ]
            }
          />
          <Field
            component={Dropdown}
            label={
              COUNTRY === 'US'
                ? `${formatMessage({
                    defaultMessage: 'State',
                  })} *`
                : `${formatMessage({
                    defaultMessage: 'Province/Territory',
                  })} *`
            }
            name="state_code"
            options={options}
          />
          <Field
            component={Input}
            label={
              COUNTRY === 'US'
                ? `${formatMessage({
                    defaultMessage: 'Zip Code',
                  })} *`
                : `${formatMessage({
                    defaultMessage: 'Postal Code',
                  })} *`
            }
            name="code"
            normalize={
              COUNTRY === 'US' ? normalizeUSZip : normalizeCanadianPostal
            }
          />
        </div>
        <div className="input-row">
          <Field
            component={Dropdown}
            label={`${formatMessage({
              defaultMessage: 'Time Zone',
            })} *`}
            name="timezone"
            options={timezones}
          />
        </div>
        <div className="input-row">
          <Field
            component={Input}
            label={`${formatMessage({
              defaultMessage: 'Business Email',
            })} *`}
            name="business_email"
          />
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="Office Phone Number" />}
            name="office_number"
            normalize={normalizePhone}
          />
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="Fax Number" />}
            name="fax_number"
            normalize={normalizePhone}
          />
        </div>
        <div className="input-row">
          <Field
            component={Input}
            label={`${formatMessage({
              defaultMessage: 'Mobile Phone Number',
            })} *`}
            name="sms_number"
            normalize={normalizePhone}
          />
          <Field
            component={Checkbox}
            name="sms_reminder"
            options={[
              {
                value: true,
                label: (
                  <FormattedMessage defaultMessage="Ok to send SMS reminders to mobile phone number" />
                ),
              },
            ]}
            style={{ maxWidth: '240px' }}
          />
        </div>
        <p className="onboarding-label">
          {`${formatMessage({
            defaultMessage: 'How did you hear about Inkblot?',
          })} *`}
        </p>
        <div className="input-row">
          <Field
            component={Dropdown}
            required
            name="referral_source"
            options={referrals}
          />
        </div>
        {otherReferral && (
          <div className="input-row">
            <Field
              component={Input}
              label={<FormattedMessage defaultMessage="Other" />}
              name="other_referral"
            />
          </div>
        )}
        <p className="onboarding-label">
          {`${formatMessage({
            defaultMessage: 'Are you clinical services tax-exempt?',
          })} *`}
        </p>
        <div className="input-row">
          <Field component={RadioButton} name="custom_tax_yn" options={yesNo} />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

LocationDetails.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  updatePersonalDetails: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  updateProviderDetails: PropTypes.func.isRequired,
  updatePhoneNumbers: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updatePracticeDetails: PropTypes.func.isRequired,
  otherReferral: PropTypes.bool.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');
  const referral = selector(state, 'referral_source');

  const states = _.map(_.get(state, 'misc.states'), s => {
    return { label: s.label, value: s.code };
  });

  return {
    otherReferral: _.get(referral, 'value') === 'Other',
    states,
  };
};

const validate = values => {
  const errors = {};

  if (!values.street)
    errors.street = <FormattedMessage defaultMessage="Required" />;

  if (!values.city)
    errors.city = <FormattedMessage defaultMessage="Required" />;

  if (!values.country)
    errors.country = <FormattedMessage defaultMessage="Required" />;

  if (!values.state)
    errors.state = <FormattedMessage defaultMessage="Required" />;

  if (!values.code)
    errors.code = <FormattedMessage defaultMessage="Required" />;

  if (values.code && COUNTRY === 'US') {
    errors.code = usPostal(values.code);
  }
  if (values.code && COUNTRY === 'CA') {
    errors.code = canadianPostal(values.code);
  }

  if (!values.timezone)
    errors.timezone = <FormattedMessage defaultMessage="Required" />;

  if (!values.sms_number)
    errors.sms_number = <FormattedMessage defaultMessage="Required" />;

  if (values.sms_number && !/^\d{3}-\d{3}-\d{4}$/i.test(values.sms_number)) {
    errors.sms_number = (
      <FormattedMessage defaultMessage="Invalid phone number" />
    );
  }

  if (!values.custom_tax_yn)
    errors.custom_tax_yn = <FormattedMessage defaultMessage="Required" />;

  if (!values.mental_health_professional) {
    errors.mental_health_professional = (
      <FormattedMessage defaultMessage="Required" />
    );
  }
  if (!values.referral_source)
    errors.referral_source = <FormattedMessage defaultMessage="Required" />;

  if (values.referral_source && !values.other_referral) {
    errors.other_referral = <FormattedMessage defaultMessage="Required" />;
  }

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(
    connect(mapStateToProps, {
      updatePersonalDetails,
      updatePreferences,
      updateProviderDetails,
      updatePhoneNumbers,
      updateAddress,
      updatePracticeDetails,
    })(LocationDetails),
  ),
);
