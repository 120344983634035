import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Close from '../../../../../images/sessions/close.svg';
import Hamburger from '../../../../../images/sessions/hamburger.svg';
import Logo from '../../../../../images/sessions/logo.svg';
import TabContainer from './TabContainer';
import Notes from './Notes';
import AudioDifficulties from './AudioDifficulties';
import Chat from './chat';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      sidebarOpen: true,
      isCouplesGuest: false,
    };
  }

  componentDidMount() {
    const { appointment, user } = this.props;

    if (appointment.id && user.id) {
      if (
        appointment.provider_type === 'couples' &&
        user.id === appointment.couples_appointment_detail.guest.id
      ) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ isCouplesGuest: true });
      }
    }
  }

  closeSidebar = () => {
    this.setState({ sidebarOpen: false, activeTab: 0 });
    document.getElementById('sidebar').style.width = '62px';
  };

  openSidebar = () => {
    this.setState({ sidebarOpen: true });
    document.getElementById('sidebar').style.width = '400px';
  };

  handleChange = tab => {
    const {
      props: { index },
    } = tab;

    this.setState({ activeTab: index });
  };

  renderCurrentTab = () => {
    const { activeTab, isCouplesGuest } = this.state;

    switch (activeTab) {
      case 0:
        return <Chat />;
      case 1:
        return isCouplesGuest ? <AudioDifficulties /> : <Notes />;
      case 2:
        return <AudioDifficulties />;
      default:
        break;
    }

    return <div />;
  };

  render() {
    const { activeTab, sidebarOpen, isCouplesGuest } = this.state;

    return (
      <div id="sidebar" className="video-sidebar">
        {sidebarOpen ? (
          <div className="sidebar-container">
            <div className="sidebar-logo">
              <Logo />
              <Close className="sidebar-pointer" onClick={this.closeSidebar} />
            </div>
            <div className="sidebar-tab-wrapper">
              <TabContainer
                activeTab={activeTab}
                handleChange={this.handleChange}
                isCouplesGuest={isCouplesGuest}
              />
            </div>
            <div className="sidebar-contents-wrapper">
              {this.renderCurrentTab()}
            </div>
          </div>
        ) : (
          <div className="sidebar-hamburger sidebar-pointer">
            <Hamburger onClick={this.openSidebar} />
          </div>
        )}
      </div>
    );
  }
}

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
};

export default Sidebar;
