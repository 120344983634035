import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'material-ui/Tabs';

import _ from 'lodash';

const NavigationTabs = ({ tabs, onChange }) => (
  <div>
    <Tabs inkBarStyle={{ background: 'black' }}>
      {_.map(tabs, ({ name }, i) => (
        <Tab
          onActive={onChange}
          buttonStyle={{
            borderBottom: '1px solid rgba(146, 180, 255, 0.65)',
            fontSize: '12px'
          }}
          label={name}
          key={i}
        />
      ))}
    </Tabs>
  </div>
);

NavigationTabs.propTypes = {
  tabs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NavigationTabs;
