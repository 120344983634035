import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import localeMessage from './localeMessage';
// eslint-disable-next-line react/prefer-stateless-function
class CouplesLocationDialog extends Component {
  render() {
    const { open, locale } = this.props;

    if (!open) return <div />;

    return (
      <div className="cld-container">
        <div className="cld-overlay" />
        <div className="cld-body">
          <h2>{localeMessage('partnerRoom', locale)}</h2>
          <div style={{ marginBottom: 20 }}>
            <button
              className="green-button"
              onClick={() => this.props.onRequestClose(true)}
              type="button"
            >
              {localeMessage('sameRoom', locale)}
            </button>
          </div>
          <div>
            <button
              className="white-button"
              onClick={() => this.props.onRequestClose(false)}
              type="button"
            >
              {localeMessage('joiningSeparately', locale)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CouplesLocationDialog.propTypes = {
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

CouplesLocationDialog.defaultProps = {
  onRequestClose: () => {},
  open: false,
};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(CouplesLocationDialog);
