/* eslint-disable camelcase */
import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateClient } from '../../../actions/clients';

class SafteySensitivity extends Component {
  constructor(props) {
    super(props);

    const safety_sensitive_position_yn = _.get(
      props.clientData,
      'personal_detail.safety_sensitive_position_yn',
    );

    this.state = {
      safety_sensitive_position_yn,
    };
  }

  handleClick = () => {
    const { clientData } = this.props;
    const { safety_sensitive_position_yn } = this.state;

    const clientID = clientData.id;
    const values = {
      personal_detail: {
        safety_sensitive_position_yn: !safety_sensitive_position_yn,
      },
    };

    this.props.updateClient(clientID, values, () => {
      this.setState(prevState => ({
        safety_sensitive_position_yn: !prevState.safety_sensitive_position_yn,
      }));
    });
  };

  render() {
    const { safety_sensitive_position_yn } = this.state;
    return (
      <div>
        <Checkbox
          onClick={this.handleClick}
          checked={safety_sensitive_position_yn}
          name="safety_sensitive_position_yn"
          label={this.props.intl.formatMessage({
            defaultMessage: 'Safety Sensitive',
          })}
          component={Checkbox}
        />
      </div>
    );
  }
}

SafteySensitivity.propTypes = {
  clientData: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(connect(null, { updateClient })(SafteySensitivity));
