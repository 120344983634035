import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EyeOpenImg from '../../../../images/global/eye-open.svg';
import EyeHideImg from '../../../../images/global/eye-hide.svg';

const inputStyle = {
  fontFamily: 'Source Sans Pro',
  width: '200px',
  height: '40px',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: 'rgba(240, 242, 245, 0.79)',
  outline: 'none',
  paddingLeft: '10px',
  paddingRight: '10px',
  color: '#0f2045',
};

class PasswordInput extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }

  toggleVisible = () => this.setState({ visible: !this.state.visible });

  render() {
    const {
      label,
      input,
      meta,
      placeholder,
      errorText,
      afterText,
    } = this.props;
    const { error, dirty } = meta;
    const { visible } = this.state;

    return (
      <div className="password-input">
        <p className="onboarding-label">{label}</p>
        <div className="input-field">
          <input
            {...input}
            placeholder={placeholder}
            style={inputStyle}
            type={visible ? 'text' : 'password'}
          />
          <div
            className="show-icon"
            onClick={() => this.toggleVisible()}
            aria-hidden="true"
          >
            {visible ? <EyeHideImg /> : <EyeOpenImg />}
          </div>
        </div>
        <span>{afterText}</span>
        {dirty && error && <p className="onboarding-error">{error}</p>}
        {errorText && <p className="onboarding-error">{errorText}</p>}
      </div>
    );
  }
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  errorText: PropTypes.string,
  afterText: PropTypes.string,
};

PasswordInput.defaultProps = {
  label: undefined,
  input: {},
  meta: {},
  placeholder: undefined,
  type: 'text',
  errorText: undefined,
  afterText: undefined,
};

export default PasswordInput;
