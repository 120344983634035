import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import FlatButton from 'material-ui/FlatButton';
import GlobalDialog from '../../global/Dialog';
import Close from '../../../../images/global/Close.svg';
import GlobalTitle from '../../global/Title';

const InfoModal = ({ handleClose, open, intl }) => {
  const { formatMessage } = intl;
  const actions = (
    <FlatButton
      label={formatMessage({ defaultMessage: 'ok' })}
      onClick={handleClose}
      className="flat-button-green"
    />
  );
  const dialog = (
    <ul>
      <li>
        {formatMessage({
          defaultMessage: 'A strong internet connection is highly recommended.',
        })}
      </li>
      <li>
        {formatMessage({
          defaultMessage:
            'If video is loading for too long or you see a white screen, click reconnect or refresh the page.',
        })}
      </li>
      <li>
        {formatMessage(
          {
            defaultMessage:
              'Please contact our team at <a>support@inkblottherapy.com</a>',
          },
          {
            a: email => <a href={`mailto:${email}`}>{email}</a>,
          },
        )}
      </li>
    </ul>
  );

  return (
    <GlobalDialog
      contentClassName="detail-modal"
      bodyClassName="dialog-body"
      modal
      open={open}
      handleClose={handleClose}
    >
      <GlobalTitle
        title={formatMessage({ defaultMessage: 'Troubleshooting' })}
      />
      {dialog}
      <div style={{ textAlign: 'center', marginTop: '40px' }}>{actions}</div>
      <Close onClick={handleClose} className="close-button-modal" />
    </GlobalDialog>
  );
};

InfoModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default injectIntl(InfoModal);
