import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const providerPayoutSettings = {
  stateName: 'providerPayout',
  dataType: 'object',
  actions: {
    fetch: 'ducks/provider_payout/FETCH_PROVIDER_PAYOUT',
    update: 'ducks/provider_payout/UPDATE_PROVIDER_PAYOUT',
    create: 'ducks/provider_payout/CREATE_PROVIDER_PAYOUT',
  },
};

export function fetchProviderPayout(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    providerPayoutSettings.actions.fetch,
    'get',
    `${API_URL}/admin/provider_payouts/${id}`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function cancelProviderPayout(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    providerPayoutSettings.actions.update,
    'put',
    `${API_URL}/admin/provider_payouts/${id}`,
    { update_type: 'cancel' },
    true,
    callback,
    errorCallback,
  );
}

export function executeProviderPayout(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    providerPayoutSettings.actions.update,
    'put',
    `${API_URL}/admin/provider_payouts/${id}`,
    { update_type: 'execute' },
    true,
    callback,
    errorCallback,
  );
}

export function updateProviderPayout(id, values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    providerPayoutSettings.actions.update,
    'put',
    `${API_URL}/admin/provider_payouts/${id}`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}

export function createProviderPayout(values, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    providerPayoutSettings.actions.create,
    'post',
    `${API_URL}/admin/provider_payouts/`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
