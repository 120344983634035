import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from '../form/Input';
import RadioButton from '../form/RadioButton';
import BottomBar from '../BottomBar';
import Dropdown from '../form/Dropdown';
import { updateUsaDetails } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

const options = [
  { value: true, label: <FormattedMessage defaultMessage="Yes" /> },
  {
    value: false,
    label: <FormattedMessage defaultMessage="No, Out of network" />,
  },
];

class ProfessionalDetail4 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your professional details." />
    );
    const usaProviderValues = {
      ...values,
      network_plans: values.plans,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating professional details..." />,
    );
    this.props
      .updateUsaDetails(usaProviderValues)
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'professional details 4',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit, inNetwork, insuranceCompanies, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Professional Details" />
          </h1>
          <div className="sub-page-indicator">4/4</div>
        </div>
        <p className="onboarding-label">
          {`${formatMessage({
            defaultMessage: 'Are you in network with any health plans?',
          })} *`}
        </p>
        <Field component={RadioButton} name="in_network" options={options} />
        {inNetwork && inNetwork === 'true' && (
          <div>
            <p className="onboarding-label">
              {`${formatMessage({
                defaultMessage: 'Which health plans are you associated with?',
              })} *`}
            </p>
            <div className="input-row">
              <Field
                component={Dropdown}
                searchable
                multi
                name="plans"
                width={300}
                options={insuranceCompanies}
              />
            </div>
          </div>
        )}
        <div className="input-row">
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="NPI Individual Number" />}
            name="npi_number_individual"
          />
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="NPI Business Number" />}
            name="npi_number_business"
          />
        </div>
        <div className="input-row">
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="Tax ID" />}
            name="tax_id"
          />
          <Field
            component={Input}
            label={
              <FormattedMessage defaultMessage="Individual Medicare Number" />
            }
            name="medicare_number"
          />
          <Field
            component={Input}
            label={
              <FormattedMessage defaultMessage="Individual Medicaid Number" />
            }
            name="medicaid_number"
          />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

ProfessionalDetail4.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  insuranceCompanies: PropTypes.array.isRequired,
  updateUsaDetails: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  inNetwork: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
};

ProfessionalDetail4.defaultProps = {
  inNetwork: 'false',
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');
  return {
    inNetwork: selector(state, 'in_network'),
  };
};

const validate = values => {
  const errors = {};

  if (!values.in_network)
    errors.in_network = <FormattedMessage defaultMessage="Required" />;
  if (values.in_network && !values.plans) {
    errors.plans = (
      <FormattedMessage defaultMessage="You must select at least 1 plan" />
    );
  }
  if (values.in_network === 'true' && !values.npi_number_individual) {
    errors.npi_number_individual = (
      <FormattedMessage defaultMessage="Required" />
    );
  }

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(connect(mapStateToProps, { updateUsaDetails })(ProfessionalDetail4)),
);
