import React from 'react';
import _ from 'lodash';
import nullParser from '../../../../utils/nullParser';

export const InfoContainer = ({
  infoTitle,
  style,
  infoDataObject,
  leftBreak,
  rightBreak,
  linebreak,
}) => (
  <div style={{ style }}>
    <h1 className="infoContainerTitle">{infoTitle}</h1>
    <div style={{ position: 'relative' }} className="row">
      <div className="col">
        {_.map(infoDataObject.slice(0, leftBreak), (info, i) => (
          <div key={i}>
            <p className="infoContainerSubtitle">{info.name}</p>
            <p className="infoContainerData">{nullParser(info.data)}</p>
          </div>
        ))}
      </div>
      <div className="divider" />
      <div className="col">
        {_.map(infoDataObject.slice(leftBreak, rightBreak), (info, i) => (
          <div key={i}>
            <p className="infoContainerSubtitle">{info.name}</p>
            <p className="infoContainerData">{nullParser(info.data)}</p>
            {info.name === 'family doctor' && (
              <hr
                style={{
                  borderTop: '2px solid #c2adff',
                  width: '25px',
                  marginLeft: 0,
                  marginTop: '10px',
                  height: '5px',
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);
