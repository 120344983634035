import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const marginBottom = { marginBottom: '30px', marginTop: '0px' };

const WelcomeCounsellors = props => {
  const { appointment } = props;
  let clientName = '';

  if (appointment) {
    clientName = get(appointment, 'client.preferred_name_with_last_name');

    if (
      appointment.provider_type === 'couples' &&
      appointment.couples_appointment_detail &&
      appointment.couples_appointment_detail.guest
    ) {
      clientName = `${clientName} and ${appointment.couples_appointment_detail.guest.full_name}`;
    }
  }
  return (
    <div>
      <h2 style={marginBottom}>
        <FormattedMessage defaultMessage="Welcome to the waiting room" />
      </h2>
      <h3 style={{ ...marginBottom, fontSize: '20px' }}>
        <FormattedMessage
          defaultMessage="Your session will begin shortly, with {name}"
          values={{ name: <b>{clientName}</b> }}
        />
      </h3>
      {appointment && appointment.provider_type === 'generic' ? (
        <p>
          <FormattedMessage defaultMessage="Please prepare any notes you may have" />
        </p>
      ) : (
        <p>
          <FormattedMessage defaultMessage="Please prepare any notes you may have, they are currently answering assessment questions." />
        </p>
      )}
      <hr style={{ margin: '0', marginBottom: '24px' }} className="line" />
    </div>
  );
};

WelcomeCounsellors.propTypes = {
  appointment: PropTypes.object,
};

WelcomeCounsellors.defaultProps = {
  appointment: null,
};

export default WelcomeCounsellors;
