import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import { injectIntl, FormattedMessage } from 'react-intl';
import Dropdown from '../form/Dropdown';
import Input from '../form/Input';
import PasswordInput from '../form/PasswordInput';
import RadioButton from '../form/RadioButton';
import Checkbox from '../form/Checkbox';
import BottomBar from '../BottomBar';
import { titles, genders, toMultiSelectOptions } from '../helpers';
import { checkEmail } from '../../../actions/misc';
import { createUser, fetchUser } from '../../../actions/user';
import {
  updatePersonalDetails,
  updatePracticePreferences,
} from '../../../actions/preferences';
import {
  START_REGISTRATION,
  ADVANCE_REGISTRATION,
  logEvent,
} from '../../../utils/amplitude';

class PersonalDetails extends Component {
  componentDidMount() {
    logEvent(START_REGISTRATION);
  }

  onSubmit = values => {
    const { customGender } = this.props;
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem creating your account." />
    );
    const userValues = customGender
      ? { ...values, gender: values.custom_gender }
      : values;
    const personalDetailValues = {
      personal_detail: {
        title: _.get(values, 'title.value'),
      },
    };

    const pronounValues = {
      pronoun: {
        pronouns: _.get(values, 'pronouns', []),
        pronoun_other_yn: _.get(values, 'pronoun_other_yn[0]', false),
        pronoun_other_name: _.get(values, 'pronoun_other_name', ''),
      },
    };
    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Creating account..." />,
    );
    this.props
      .createUser({
        ...userValues,
        locale: localStorage.getItem('locale') || 'en',
        source: 'practice',
      })
      .then(() => {
        this.props.fetchUser(() => {
          logEvent(ADVANCE_REGISTRATION, {
            page: 'personal details',
          });
          this.props.updatePersonalDetails(personalDetailValues);
          this.props.updatePracticePreferences(pronounValues);
          this.props.closeLoadingPanel();
          this.props.nextPage();
        });
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  checkEmail = event => this.props.checkEmail(event.target.value);

  render() {
    const {
      customGender,
      handleSubmit,
      emailError,
      pronounOptions,
      isPronounOtherVisible,
      intl,
    } = this.props;
    const { formatMessage } = intl;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title" style={{ marginBottom: '20px' }}>
          <h1>
            <FormattedMessage defaultMessage="Personal Details" />
          </h1>
        </div>
        <h4 style={{ marginBottom: '40px' }}>
          <FormattedMessage defaultMessage="Only your name will be visible to your clients" />
        </h4>
        <div className="input-row">
          <Field
            component={Dropdown}
            label={formatMessage({
              defaultMessage: 'Title',
            })}
            name="title"
            options={titles}
          />
          <Field
            component={Input}
            required
            label={`${formatMessage({
              defaultMessage: 'First Name',
            })} *`}
            name="first_name"
          />
          <Field
            component={Input}
            required
            label={`${formatMessage({
              defaultMessage: 'Last Name',
            })} *`}
            name="last_name"
          />
        </div>
        <div className="input-row">
          <Field
            component={Dropdown}
            label={formatMessage({
              defaultMessage: 'Pronouns',
            })}
            multi
            name="pronouns"
            options={pronounOptions}
          />
          <Field
            component={Checkbox}
            name="pronoun_other_yn"
            options={[
              {
                value: true,
                label: formatMessage({
                  defaultMessage: 'Other',
                }),
              },
            ]}
            style={{ marginTop: 40 }}
          />
          {isPronounOtherVisible && (
            <Field
              component={Input}
              label={<span>&zwnj;</span>}
              name="pronoun_other_name"
            />
          )}
        </div>
        <div className="input-row">
          <Field
            component={Input}
            required
            label={`${formatMessage({ defaultMessage: 'Email' })} *`}
            name="email"
            onChange={_.debounce(event => this.checkEmail(event), 300)}
            errorText={emailError}
          />
          <Field
            component={Input}
            required
            label={`${formatMessage({ defaultMessage: 'Confirm Email' })} *`}
            name="confirm_email"
          />
        </div>
        <div className="input-row">
          <Field
            component={PasswordInput}
            label={`${formatMessage({ defaultMessage: 'Password' })} *`}
            name="password"
          />
          <Field
            component={PasswordInput}
            required
            label={`${formatMessage({ defaultMessage: 'Confirm Password' })} *`}
            name="confirm_password"
          />
        </div>
        <FormattedMessage defaultMessage="Gender">
          {label => <p className="onboarding-label required">{label}</p>}
        </FormattedMessage>
        <div className="input-row">
          <Field component={RadioButton} name="gender" options={genders} />
        </div>
        {customGender && (
          <div className="input-row">
            <Field
              component={Input}
              label={formatMessage({
                defaultMessage: 'Custom',
              })}
              hhhname="custom_gender"
            />
          </div>
        )}
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PersonalDetails.propTypes = {
  checkEmail: PropTypes.func.isRequired,
  emailError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  customGender: PropTypes.bool,
  openLoadingPanel: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updatePersonalDetails: PropTypes.func.isRequired,
  updatePracticePreferences: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  pronounOptions: PropTypes.array.isRequired,
  isPronounOtherVisible: PropTypes.bool,
  intl: PropTypes.object,
  formatMessage: PropTypes.func,
};

PersonalDetails.defaultProps = {
  customGender: false,
  emailError: '',
  isPronounOtherVisible: false,
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');
  const gender = selector(state, 'gender');

  return {
    customGender: gender === 'custom',
    emailError: state.misc.emailError,
    pronounOptions: toMultiSelectOptions(
      _.get(state.misc, 'pronouns', []).filter(
        pronoun => pronoun.other_yn === false,
      ),
    ),
    isPronounOtherVisible: selector(state, 'pronoun_other_yn[0]'),
  };
};

const validate = values => {
  const errors = {};

  if (!values.first_name)
    errors.first_name = <FormattedMessage defaultMessage="Required" />;
  if (!values.last_name)
    errors.last_name = <FormattedMessage defaultMessage="Required" />;

  if (!values.email)
    errors.email = <FormattedMessage defaultMessage="Required" />;

  if (!values.confirm_email)
    errors.confirm_email = <FormattedMessage defaultMessage="Required" />;

  if (
    values.email &&
    !/^[À-ÿA-Z0-9._%+-]+@[À-ÿA-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)
  ) {
    errors.email = <FormattedMessage defaultMessage="Invalid email address" />;
  }
  if (values.email !== values.confirm_email) {
    errors.confirm_email = (
      <FormattedMessage defaultMessage="Emails do not match" />
    );
  }

  if (!values.password)
    errors.password = <FormattedMessage defaultMessage="Required" />;
  if (values.password && values.password.length < 8) {
    errors.password = (
      <FormattedMessage defaultMessage="Password must be at least 8 characters" />
    );
  }
  if (!values.confirm_password)
    errors.confirm_password = <FormattedMessage defaultMessage="Required" />;

  if (values.password !== values.confirm_password) {
    errors.confirm_password = (
      <FormattedMessage defaultMessage="Passwords do not match" />
    );
  }

  if (!values.gender)
    errors.gender = <FormattedMessage defaultMessage="Required" />;

  if (!values.custom_gender)
    errors.custom_gender = <FormattedMessage defaultMessage="Required" />;

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(
    connect(mapStateToProps, {
      checkEmail,
      createUser,
      updatePersonalDetails,
      updatePracticePreferences,
      fetchUser,
    })(PersonalDetails),
  ),
);
