import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import BottomBar from './BottomBar';
import Sidebar from './sidebar';
import Screen from './Screen';
import {
  fetchAppointment,
  updateAppointmentSession,
  updateAppointment,
} from '../../../actions/sessions';
import { fetchUser } from '../../../actions/user';
import { updateUserAppointment } from '../../../ducks/sessions/userAppointment';
import LoadingPanel from '../../global/LoadingPanel';
import CompleteSessionDialog from './CompleteSessionDialog';
import CouplesLocationDialog from './CouplesLocationDialog';

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      cameraOpen: true,
      audioOpen: true,
      couplesLocation: {
        open: false,
        answered: false,
        same: false,
      },
    };
    this.twilioControl = {
      toggleCamera: () => {},
      toggleMic: () => {},
    };
  }

  componentDidMount() {
    const appointmentId = this.props.match.params.id;
    this.props.fetchAppointment(appointmentId, apptRes => {
      this.props.fetchUser(userRes => {
        const appointment = apptRes.data;
        const user = userRes.data;

        if (
          !(appointment.provider_type === 'couples' &&
            user.id === appointment.couples_appointment_detail.guest.id)
        ) {
          if (appointment.status === 'scheduled') {
            this.props.updateAppointment(appointmentId, { status: 'pending' });
          }

          this.props.updateUserAppointment(appointmentId, { waiting_yn: true });

          // appointmentId belonging to the current user
          const appointmentSession = _.find(
            appointment.appointment_sessions,
            { user_id: _.get(user, 'id') },
          );

          // lets us know the user joined
          if (appointmentSession) {
            this.props.updateAppointmentSession(appointmentSession.id, {
              joined_yn: true,
              source: "web",
            });
          }
        }

        if (appointment.provider_type === 'couples') {
          if (user.id === appointment.couples_appointment_detail.host.id) {
            this.setState({ couplesLocation: { open: true, answered: false, same: false } });
          } else {
            this.setState({ couplesLocation: { open: false, answered: true, same: false } });
          }
        }
      });
    });
  }

  openDialog = () => this.setState({ dialogOpen: true });
  closeDialog = () => this.setState({ dialogOpen: false });

  toggleCamera = () => {
    this.twilioControl.toggleCamera();
    this.setState(prevState => ({ cameraOpen: !prevState.cameraOpen }));
  };

  toggleMic = () => {
    this.twilioControl.toggleMic();
    this.setState(prevState => ({ audioOpen: !prevState.audioOpen }));
  };

  callbackForTwillio = controls => {
    if (controls.toggleCamera) {
      this.twilioControl.toggleCamera = controls.toggleCamera;
    }

    if (controls.toggleMic) {
      this.twilioControl.toggleMic = controls.toggleMic;
    }
  };

  closeCouplesLocationDialog = isSameLocation =>
    this.setState({ couplesLocation: { open: false, answered: true, same: isSameLocation } })

  render() {
    const { connected, appointment, user } = this.props;
    const { provider_type } = appointment;
    const {
      dialogOpen, cameraOpen, audioOpen, couplesLocation,
    } = this.state;
    const userId = this.props.user && this.props.user.id;

    if (!appointment.id || !user.id) {
      return <LoadingPanel />;
    }

    return (
      <div className="client-session">
        {(provider_type !== 'couples' || couplesLocation.answered) && (
          <div className="top-container">
            <Screen
              userId={userId}
              channel={this.props.match.params.id}
              callbackForTwillio={this.callbackForTwillio}
              sessionType={provider_type}
              couplesSameLocation={couplesLocation.same}
              appointment={appointment}
            />
            <Sidebar
              user={user}
              appointment={appointment}
            />
          </div>
        )}
        <BottomBar
          connected={connected}
          appointment={appointment}
          toggleCamera={this.toggleCamera}
          toggleMic={this.toggleMic}
          openDialog={this.openDialog}
          cameraOpen={cameraOpen}
          audioOpen={audioOpen}
          user={user}
        />
        <CompleteSessionDialog
          open={dialogOpen}
          onRequestClose={this.closeDialog}
        />
        <CouplesLocationDialog
          open={couplesLocation.open}
          onRequestClose={this.closeCouplesLocationDialog}
        />
      </div>
    );
  }
}

Video.propTypes = {
  fetchAppointment: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  updateAppointmentSession: PropTypes.func.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  appointment: PropTypes.object,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  connected: PropTypes.bool.isRequired,
  updateUserAppointment: PropTypes.func.isRequired,
};

Video.defaultProps = {
  appointment: {},
};

function mapStateToProps(state) {
  return {
    appointment: state.appointments.appointment,
    providerYn: state.user.provider_yn,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  fetchAppointment,
  fetchUser,
  updateAppointmentSession,
  updateAppointment,
  updateUserAppointment,
})(reduxForm({
  form: 'VideoForm',
  enableReinitialize: true,
})(Video));
