import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const clientSessionNotesSettings = {
  stateName: 'clientSessionNotes',
  dataType: 'object',
  actions: {
    fetch: 'ducks/client_session_notes/FETCH_CLIENT_SESSION_NOTES',
  },
};

export function fetchClientSessionNotes(
  clientId,
  page = 1,
  filters,
  callback = () => {},
  errorCallback = () => {},
) {
  const filterString = Object.keys(filters)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`)
    .join('&');

  return ducksActionCreator(
    clientSessionNotesSettings.actions.fetch,
    'get',
    `${API_URL}/admin/documents?client_id=${clientId}&page=${page}&${filterString}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
