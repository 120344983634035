import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';

import { fetchAppointmentNote, updateAppointmentNote } from '../../../../actions/sessions';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, 'blockquote'],
  ],
};

const formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'align'];

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    const { appointmentNote } = this.props;

    this.props.fetchAppointmentNote(appointmentNote.id, response => {
      const text = response.data.note;
      this.setState({ text });
    });
  }

  updateNote = _.debounce(
    value => this.props.updateAppointmentNote(this.props.appointmentNote.id, { note: value }),
    500,
  );

  handleChange = text => {
    this.setState({ text });
    this.updateNote(text);
  };

  render() {
    const { text } = this.state;

    return (
      <div className="sidebar-notes-container sidebar-flex">
        <ReactQuill formats={formats} modules={modules} value={text} onChange={this.handleChange} />
        <p className="sidebar-changes-saved">Your changes are automatically saved.</p>
      </div>
    );
  }
}

Notes.propTypes = {
  appointmentNote: PropTypes.object,
  fetchAppointmentNote: PropTypes.func.isRequired,
  updateAppointmentNote: PropTypes.func.isRequired,
};

Notes.defaultProps = {
  appointmentNote: { note: '' },
};

function mapStateToProps(state) {
  return {
    appointmentNote: _.get(state, 'appointments.appointment.appointment_notes[0]'),
  };
}

export default connect(
  mapStateToProps,
  { fetchAppointmentNote, updateAppointmentNote },
)(Notes);
