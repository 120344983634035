import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const providerPayoutSourceSettings = {
  stateName: 'providerPayoutSource',
  dataType: 'object',
  actions: {
    fetch: 'ducks/provider_payout_source/FETCH_SOURCE',
    create: 'ducks/provider_payout_source/CREATE_SOURCE',
  },
};

export function fetchProviderPayoutSource(
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    providerPayoutSourceSettings.actions.fetch,
    'get',
    `${API_URL}/admin/provider_payout_sources/default`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function createProviderPayoutSource(
  values,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    providerPayoutSourceSettings.actions.create,
    'post',
    `${API_URL}/admin/provider_payout_sources`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
