import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const LeftMessageBubble = props => (
  <div className="message">
    <p className="message-timestamp">
      {moment(props.timestamp).format('LT')}
    </p>
    <p className="chat-message-body">{props.body}</p>
  </div>
);

LeftMessageBubble.propTypes = {
  timestamp: PropTypes.object,
  body: PropTypes.string,
};

export default LeftMessageBubble;
