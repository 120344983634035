import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { InfoContainer } from './partials';
import SafteySensitivity from './SafteySensitivity';

const horizontalLine = {
  borderTop: '2px solid #c2adff',
  width: '25px',
  marginLeft: 0,
  marginTop: '10px',
};

const propTypes = {
  intl: PropTypes.object.isRequired,
};

class Demographics extends Component {
  static propTypes = {
    clientData: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { clientData, intl } = this.props;
    const { formatMessage } = intl;

    const basicInfo = [
      {
        name: formatMessage({ defaultMessage: 'Client Name' }),
        data: `${_.get(clientData, 'preferred_name_with_last_name', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Email' }),
        data: `${_.get(clientData, 'email', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Primary Phone' }),
        data: `${_.get(clientData, 'primary_number.number', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Address' }),
        data: `${_.get(clientData, 'address.street', '')} ${_.get(
          clientData,
          'address.alternate_street',
          '',
        )} ${_.get(clientData, 'address.city', '')}, ${_.get(
          clientData,
          'address.state.code',
          '',
        )} ${_.get(clientData, 'address.code', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Timezone' }),
        data: `${_.get(clientData, 'preference.timezone', '')}`,
      },
    ];

    const medicalInfo = [
      {
        name: formatMessage({ defaultMessage: 'Coverage' }),
        data: `${
          _.get(clientData, 'personal_detail.coverage_yn')
            ? formatMessage({ defaultMessage: 'They have Coverage' })
            : formatMessage({ defaultMessage: 'No Coverage' })
        }`,
      },
      {
        name: formatMessage({ defaultMessage: 'Personal Health Number' }),
        data: `${_.get(clientData, 'personal_detail.health_number', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Legal Gender' }),
        data: `${_.get(clientData, 'personal_detail.legal_gender', '')}`,
      },
      {
        // should refactor this address, its very messy and null values, make default to empty string "".
        name: formatMessage({ defaultMessage: 'Occupation' }),
        data: `${_.get(clientData, 'personal_detail.occupation', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Family Doctor' }),
        data: `${_.get(clientData, 'contact_primary.name', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Emergency Contact' }),
        data: `${_.get(clientData, 'contact_emergency.name', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Phone' }),
        data: `${_.get(clientData, 'contact_emergency.phone', '')}`,
      },
      {
        name: formatMessage({ defaultMessage: 'Relationship' }),
        data: `${_.get(clientData, 'contact_emergency.relationship', '')}`,
      },
    ];

    return (
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ fontSize: '20px' }}>
          {_.get(clientData, 'preferred_name_with_last_name', '')}
        </h1>
        <hr style={horizontalLine} />
        <InfoContainer
          infoDataObject={basicInfo}
          infoTitle={this.props.intl.formatMessage({
            defaultMessage: 'Basics',
          })}
          leftBreak={3}
          rightBreak={5}
        />
        <InfoContainer
          infoDataObject={medicalInfo}
          infoTitle={this.props.intl.formatMessage({
            defaultMessage: 'Medical',
          })}
          leftBreak={4}
          rightBreak={8}
        />
        <SafteySensitivity clientData={clientData} />
      </div>
    );
  }
}

Demographics.propTypes = propTypes;

export default injectIntl(Demographics);
