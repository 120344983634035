import React from 'react';

import InactiveChat from '../../../../../images/sessions/inactive-chat.svg';
import InactiveNotes from '../../../../../images/sessions/inactive-notes.svg';
import InactiveAudio from '../../../../../images/sessions/inactive-audio.svg';

import ActiveChat from '../../../../../images/sessions/active-chat.svg';
import ActiveNotes from '../../../../../images/sessions/active-notes.svg';
import ActiveAudio from '../../../../../images/sessions/active-audio.svg';

export const tabs = [
  {
    index: 0,
    name: 'Chat',
    inactiveIcon: <InactiveChat />,
    activeIcon: <ActiveChat />,
  },
  {
    index: 1,
    name: 'Notes',
    inactiveIcon: <InactiveNotes />,
    activeIcon: <ActiveNotes />,
  },
  {
    index: 2,
    name: 'Audio Difficulties',
    inactiveIcon: <InactiveAudio />,
    activeIcon: <ActiveAudio />,
  },
];
