import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import TextArea from '../form/TextArea';
import BottomBar from '../BottomBar';
import { COUNTRY } from '../../../environment';
import {
  updateDesignations,
  updateAdditionalCertifications,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class ProfessionalDetails2 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your professional details." />
    );
    const designationValues = {
      designations: values.designations,
    };
    const additionalDesignationValues = {
      additional_designations: {
        certifications:
          values.additional_designations &&
          values.additional_designations.split(','),
      },
      type_of: 'designations',
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating professional details..." />,
    );
    Promise.all([
      this.props.updateDesignations(designationValues),
      this.props.updateAdditionalCertifications(additionalDesignationValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'professional details 2',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { designations, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Professional Details" />
          </h1>
          <div className="sub-page-indicator">
            2/{COUNTRY === 'US' ? '4' : '3'}
          </div>
        </div>
        <h3 style={{ marginBottom: '20px' }}>
          <FormattedMessage defaultMessage="Designation (select all that apply)" />
        </h3>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="designations"
            options={designations}
          />
        </div>
        <p className="onboarding-label">
          <FormattedMessage defaultMessage="Other Designations (separate by comma)" />
        </p>
        <div className="input-row">
          <Field component={TextArea} name="additional_designations" />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

ProfessionalDetails2.propTypes = {
  designations: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateDesignations: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  updateAdditionalCertifications: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};

  if (isEmpty(values.designations)) {
    errors.designations = (
      <FormattedMessage defaultMessage="Must select at least one designation" />
    );
  }

  return errors;
};

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(
  connect(null, { updateDesignations, updateAdditionalCertifications })(
    ProfessionalDetails2,
  ),
);
