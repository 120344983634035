import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import BottomBar from '../BottomBar';
import { sendWelcomeEmail } from '../../../actions/misc';
import Teamwork from '../../../../images/onboarding/practitioner/teamwork.svg';
import { FINISH_REGISTRATION, logEvent } from '../../../utils/amplitude';

class ThankYou extends Component {
  componentDidMount() {
    logEvent(FINISH_REGISTRATION);
    this.props.sendWelcomeEmail();
  }

  render() {
    return (
      <div>
        <Teamwork style={{ marginBottom: '40px' }} />
        <FormattedMessage
          tagName="h1"
          defaultMessage="Congratulations for registering with Inkblot!"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="You can now go to your dashboard."
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="If you applied to access new clients through Inkblot, a member of our
          clinical services team will be in touch via email once we determine
          your eligibility and when recruitment for your jurisdiction is open."
        />
        <p>
          <FormattedMessage
            tagName="p"
            defaultMessage="For technical support, just click on the green speech bubble on the
          bottom right hand corner of any screen."
          />
        </p>
        <p>
          <FormattedMessage
            tagName="p"
            defaultMessage="Please let us know if you have any questions or concerns and thank you for the care you provide!"
          />
        </p>
        <BottomBar {...this.props} />
      </div>
    );
  }
}

ThankYou.propTypes = {
  sendWelcomeEmail: PropTypes.func.isRequired,
};

export default connect(null, { sendWelcomeEmail })(ThankYou);
