// URL takes optional access_token param
// Access token gets removed from the URL
// it sets storage of that token
// reloads page to auth

const fromTherapyAuth = props => {
  localStorage.clear();
  const URL = props.match.url.substr(0, props.match.url.lastIndexOf('/'));
  props.history.push(URL);
  if (!localStorage.getItem('token')) {
    localStorage.setItem('token', props.match.params.token);
    location.reload();
  }
};

export default fromTherapyAuth;
