import { createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video';
import { createIntl, createIntlCache } from 'react-intl';
import French from '../../../lang/fr.json';
import English from '../../../lang/en.json';

const intl = () => {
  const cache = createIntlCache();
  const locale = localStorage.getItem('locale') || 'en';
  const messages = locale === 'en' ? English : French;

  return createIntl({ locale, messages }, cache);
};

const { formatMessage } = intl();

export const trackSubscribed = (div, track) => {
  div.appendChild(track.attach());
};

export const trackUnsubscribed = track => {
  track.detach().forEach(element => element.remove());
};

export const participantConnected = participant => {
  console.log('Participant "%s" connected ppc', participant.identity);

  const div = document.createElement('div');
  div.id = participant.sid;

  div.style.width = '100%';
  div.style.height = '100%';

  participant.on('trackSubscribed', track => trackSubscribed(div, track));
  participant.on('trackUnsubscribed', track => trackUnsubscribed(track));

  const mediaDiv = document.getElementById('remote-media-div');
  const providerMedia = document.getElementById('remote-provider-media');
  const hostMedia = document.getElementById('remote-host-media');
  const guestMedia = document.getElementById('remote-guest-media');
  const partnerMedia = document.getElementById('remote-partner-media');
  const remoteCouplesDiv = document.getElementById('remote-couples');

  if (!participant.identity.includes('voice')) {
    if (participant.identity.includes('provider')) {
      (providerMedia || mediaDiv).appendChild(div);
    } else if (participant.identity.includes('host')) {
      if (participant.identity.includes('together')) {
        if (remoteCouplesDiv) remoteCouplesDiv.style.display = 'none';
        mediaDiv.appendChild(div);
      } else {
        if (remoteCouplesDiv) remoteCouplesDiv.style.display = 'flex';
        (hostMedia || partnerMedia || mediaDiv).appendChild(div);
      }
    } else if (participant.identity.includes('guest')) {
      if (remoteCouplesDiv) remoteCouplesDiv.style.display = 'flex';
      (guestMedia || partnerMedia || mediaDiv).appendChild(div);
    } else {
      mediaDiv.appendChild(div);
    }
  }
};

export const participantDisconnected = participant => {
  console.log('Participant "%s" disconnected', participant.identity);
  console.log(participant.sid, 'participant disconnected sid');
  document.getElementById(participant.sid).remove();
};

// Log any Participants already connected to the Room
export const logger = room => {
  room.participants.forEach(participant => {
    console.log(
      `Participant "${participant.identity}" is connected to the Room`,
    );
  });

  // Log new Participants as they connect to the Room
  room.once('participantConnected', participant => {
    console.log(
      `Participant "${participant.identity}" has connected to the Room`,
    );
  });

  // Log Participants as they disconnect from the Room
  room.once('participantDisconnected', participant => {
    console.log(
      `Participant "${participant.identity}" has disconnected from the Room`,
    );
  });
};

export function participantIsVoice(room) {
  room.participants.forEach(participant => {
    if (participant.identity.includes('voice')) {
      this.setState({ participantIsVoice: true });
    } else {
      this.setState({ participantIsVoice: false });
    }
  });
}

// all the twilio listeners
export function initRoom(room) {
  this.setState({ room }, () => {
    logger(this.state.room);

    createLocalVideoTrack().then(localVideoTrack => {
      room.localParticipant.publishTrack(localVideoTrack);
      const localMediaContainer = document.getElementById('local-media');
      localMediaContainer.appendChild(localVideoTrack.attach());
      this.setState({ localVideoTrackId: localVideoTrack.id });
    });

    createLocalAudioTrack().then(localAudioTrack => {
      room.localParticipant.publishTrack(localAudioTrack);
      this.setState({ localAudioTrackId: localAudioTrack.id });
    });

    this.state.room.on('participantConnected', () => {
      participantIsVoice.bind(this)(this.state.room);
    });

    this.state.room.participants.forEach(participantConnected);
    this.state.room.on('participantConnected', participantConnected);

    this.state.room.on('participantDisconnected', participantDisconnected);

    // Attach the Participant's Media to a <div> element.
    this.state.room.once('disconnected', () =>
      room.participants.forEach(participantDisconnected),
    );

    // on close browser we disconnect them
    window.addEventListener('beforeunload', () => {
      this.state.room.disconnect();
    });
  });
}
export function toggleCamera() {
  const { room } = this.state;
  const { localParticipant } = room;
  if (room) {
    this.setState(prevState => ({
      cameraOpen: !prevState.cameraOpen,
    }));
    if (!this.state.cameraOpen) {
      createLocalVideoTrack().then(track => {
        const localMediaContainer = document.getElementById('local-media');
        localMediaContainer.appendChild(track.attach());
        return localParticipant.publishTrack(track);
      });
      // localParticipant.videoTracks.forEach(function(videoTrack) {
      //   videoTrack.track.enable();
      // });
    } else {
      const track = localParticipant.videoTracks.values().next().value;
      track.track.stop();
      track.track.detach().forEach(element => element.remove());
      localParticipant.unpublishTrack(track.track);
    }
  }
}

export function toggleMic() {
  const { room } = this.state;
  const { localParticipant } = room;
  if (room) {
    this.setState(prevState => ({
      audioOpen: !prevState.audioOpen,
    }));
    if (!this.state.audioOpen) {
      localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.enable();
      });
    } else {
      localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.disable();
      });
    }
  }
}

export const networkColor = level => {
  if (level < 2) {
    // red
    return '#B81D13';
  }
  if (level < 4) {
    // yellow
    return '#EFB700';
  }
  // green
  return '#008450';
};

export const networkQuality = {
  1: '▃',
  2: '▃▄',
  3: '▃▄▅',
  4: '▃▄▅▆',
  5: '▃▄▅▆▇',
};

export function printNetworkQualityStats(
  networkQualityLevel,
  networkQualityStats,
) {
  // Print in console the networkQualityLevel using bars

  // console.log(networkQualityLevel);
  this.setState({ networkQualityLevel });
  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    // console.log('Network Quality statistics:', networkQualityStats);
  }
}

export function detectHardware() {
  const constraints = { video: true, audio: true };
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(() => {
      this.setState({
        hardwareError: '',
        hardwareErrorCode: '',
      });
    })
    .catch(error => {
      const { name } = error;
      if (name === 'NotAllowedError') {
        this.setState({
          hardwareError: formatMessage({
            defaultMessage:
              'Your browser is not permitting access to your camera/microphone, please refresh after fixing.',
          }),
          hardwareErrorCode: name,
        });
      } else if (name === 'NotFoundError') {
        this.setState({
          hardwareError: formatMessage({
            defaultMessage:
              'Unable to detect a camera/microphone, please refresh after fixing.',
          }),
          hardwareErrorCode: name,
        });
      } else if (name === 'NotReadableError') {
        this.setState({
          hardwareError: formatMessage({
            defaultMessage:
              'A hardware issue has occurred with your camera/microphone, please refresh after fixing.',
          }),
          hardwareErrorCode: name,
        });
      } else {
        this.setState({
          hardwareError: formatMessage({
            defaultMessage: 'Unknown Error',
          }),
          hardwareErrorCode: 'UnknownError',
        });
      }
    });
}
