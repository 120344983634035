import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MultiSelectCheckbox = ({ input, options, label, meta, style }) => {
  const { name, value, onChange } = input;
  const { touched, error } = meta;

  return (
    <div style={{ position: 'relative', ...style }}>
      <p className="onboarding-label">{label}</p>
      {_.map(options, (option, i) => (
        <div className="multi-select-checkbox" key={i}>
          <input
            type="checkbox"
            name={`${name}[${i}]`}
            value={option.value}
            checked={value.indexOf(option.value) !== -1}
            onChange={event => {
              const newValue = [...value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }

              return onChange(newValue);
            }}
          />
          <div className="box">
            <span>{option.label}</span>
          </div>
        </div>
      ))}
      {touched && error && <p className="onboarding-error">{error}</p>}
    </div>
  );
};

MultiSelectCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
};

MultiSelectCheckbox.defaultProps = {
  label: undefined,
  style: undefined,
};

export default MultiSelectCheckbox;
