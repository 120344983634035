import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import BottomBar from '../BottomBar';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import Checkbox from '../form/Checkbox';
import Input from '../form/Input';
import { updatePracticePreferences } from '../../../actions/preferences';
import { toMultiSelectOptions } from '../helpers';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class PracticeDetails5 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );
    const params = {
      gender_identity: {
        gender_identities: _.get(values, 'gender_identities', []).map(id => ({
          id,
        })),
        gender_identity_other_yn: _.get(
          values,
          'gender_identity_other_yn[0]',
          false,
        ),
        gender_identity_other_name: _.get(
          values,
          'gender_identity_other_name',
          '',
        ),
      },
      race: {
        races: _.get(values, 'races', []).map(id => ({ id })),
        race_other_yn: _.get(values, 'race_other_yn[0]', false),
        race_other_name: _.get(values, 'race_other_name', ''),
      },
      provider_self_identification: {
        display_gender_identity_yn: _.get(
          values,
          'display_gender_identity_yn[0]',
          false,
        ),
        with_disability_yn: _.get(values, 'with_disability_yn[0]', false),
        display_with_disability_yn: _.get(
          values,
          'display_with_disability_yn[0]',
          false,
        ),
        neurodivergent_yn: _.get(values, 'neurodivergent_yn[0]', false),
        display_neurodivergent_yn: _.get(
          values,
          'display_neurodivergent_yn[0]',
          false,
        ),
        lgbtq2sp_yn: _.get(values, 'lgbtq2sp_yn[0]', false),
        display_lgbtq2sp_yn: _.get(values, 'display_lgbtq2sp_yn[0]', false),
        display_race_yn: _.get(values, 'display_race_yn[0]', false),
      },
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );
    this.props
      .updatePracticePreferences(params)
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 5',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const {
      genderIdentityOptions,
      isGenderIdentityOtherVisible,
      raceOptions,
      isRaceOtherVisible,
      handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <FormattedMessage tagName="h1" defaultMessage="Practice Details" />
          <div className="sub-page-indicator">5/6</div>
        </div>
        <FormattedMessage tagName="h3" defaultMessage="Gender Identity" />
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="gender_identities"
            options={genderIdentityOptions}
          />
        </div>
        <div className="input-row" style={{ marginTop: -75 }}>
          <Field
            component={Checkbox}
            name="gender_identity_other_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Other" />,
              },
            ]}
            style={{ marginTop: 40 }}
          />
          {isGenderIdentityOtherVisible && (
            <Field
              component={Input}
              label={<span>&zwnj;</span>}
              name="gender_identity_other_name"
            />
          )}
        </div>
        <div className="input-row" style={{ marginTop: -47, marginBottom: 30 }}>
          <Field
            component={Checkbox}
            name="display_gender_identity_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Display on profile" />,
              },
            ]}
            style={{ width: 900 }}
          />
        </div>
        <FormattedMessage
          tagName="h3"
          defaultMessage="Person Living with a Disability"
        />
        <div className="input-row">
          <Field
            component={Checkbox}
            name="with_disability_yn"
            options={[
              {
                value: true,
                label: (
                  <FormattedMessage defaultMessage="I identify as a person living with a disability" />
                ),
              },
            ]}
          />
        </div>
        <div className="input-row" style={{ marginTop: -47, marginBottom: 30 }}>
          <Field
            component={Checkbox}
            name="display_with_disability_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Display on profile" />,
              },
            ]}
          />
        </div>
        <FormattedMessage tagName="h3" defaultMessage="Neurodivergent Person" />
        <div className="input-row">
          <Field
            component={Checkbox}
            name="neurodivergent_yn"
            options={[
              {
                value: true,
                label: (
                  <FormattedMessage defaultMessage="I identify as a neurodivergent person" />
                ),
              },
            ]}
          />
        </div>
        <div className="input-row" style={{ marginTop: -47, marginBottom: 30 }}>
          <Field
            component={Checkbox}
            name="display_neurodivergent_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Display on profile" />,
              },
            ]}
          />
        </div>
        <h3>
          <FormattedMessage defaultMessage="LGBTQ2S+" />
        </h3>
        <div className="input-row">
          <Field
            component={Checkbox}
            name="lgbtq2sp_yn"
            options={[
              {
                value: true,
                label: (
                  <FormattedMessage defaultMessage="I identify as LGBTQ2S+" />
                ),
              },
            ]}
          />
        </div>
        <div className="input-row" style={{ marginTop: -47, marginBottom: 30 }}>
          <Field
            component={Checkbox}
            name="display_lgbtq2sp_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Display on profile" />,
              },
            ]}
          />
        </div>
        <FormattedMessage
          tagName="h3"
          defaultMessage="Racial/Ethnic Identity"
        />
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="races"
            options={raceOptions}
          />
        </div>
        <div className="input-row" style={{ marginTop: -75 }}>
          <Field
            component={Checkbox}
            name="race_other_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Other" />,
              },
            ]}
            style={{ marginTop: 40 }}
          />
          {isRaceOtherVisible && (
            <Field
              component={Input}
              label={<span>&zwnj;</span>}
              name="race_other_name"
            />
          )}
        </div>
        <div className="input-row" style={{ marginTop: -47 }}>
          <Field
            component={Checkbox}
            name="display_race_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Display on profile" />,
              },
            ]}
            style={{ width: 900 }}
          />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails5.propTypes = {
  genderIdentityOptions: PropTypes.array.isRequired,
  isGenderIdentityOtherVisible: PropTypes.bool,
  raceOptions: PropTypes.array.isRequired,
  isRaceOtherVisible: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updatePracticePreferences: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

PracticeDetails5.defaultProps = {
  isGenderIdentityOtherVisible: false,
  isRaceOtherVisible: false,
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');
  return {
    genderIdentityOptions: toMultiSelectOptions(
      _.get(state.misc, 'genderIdentities', []).filter(
        identity => identity.other_yn === false,
      ),
    ),
    isGenderIdentityOtherVisible: selector(
      state,
      'gender_identity_other_yn[0]',
    ),
    raceOptions: toMultiSelectOptions(
      _.get(state.misc, 'races', []).filter(race => race.other_yn === false),
    ),
    isRaceOtherVisible: selector(state, 'race_other_yn[0]'),
  };
};

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, { updatePracticePreferences })(PracticeDetails5));
