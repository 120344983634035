import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField/TextField';
import _ from 'lodash';
import { DropDownMenu, MenuItem } from 'material-ui';
import SvgIcon from 'material-ui/SvgIcon';
import { updateAppointment } from '../../../ducks/dashboard/appointment';
import GlobalDialog from '../../global/Dialog';
import Spinner from '../../../../images/global/spinner.svg';
import Close from '../../../../images/global/Close.svg';
import GlobalTitle from '../../global/Title';

class CompleteSessionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointment: {
        status: 'pending',
        feedback: '',
      },
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => this.setState({ appointment: this.props.appointment });

  handleFeedbackChange = event => {
    const { value } = event.target;

    this.setState(prevState => ({
      appointment: {
        ...prevState.appointment,
        feedback: value,
      },
    }));
  };

  submitStatus = () => {
    const { appointment } = this.state;
    this.setState({ loading: true });
    this.props.updateAppointment(
      appointment.id,
      {
        process_payment:
          (appointment.status === 'completed' ||
            appointment.status === 'completed_external') &&
          appointment.payment_method === 'inkblot' &&
          !appointment.paid_yn,
        status:
          appointment.status === 'scheduled' ? 'pending' : appointment.status,
        process_absent_charge:
          appointment.status === 'absent' &&
          appointment.payment_method === 'inkblot',
        feedback: appointment.feedback,
      },
      () => {
        this.setState({ loading: false });
        this.props.toggleModal();
        this.props.disconnect();
      },
    );
  };

  renderPaymentWarning = () => {
    const { appointment } = this.state;
    const { formatMessage } = this.props.intl;

    if (
      (appointment.status === 'completed' ||
        appointment.status === 'completed_external') &&
      !appointment.paid_yn &&
      appointment.payment_method === 'inkblot'
    ) {
      return (
        <p
          className="error-text"
          style={{ fontSize: 16, marginTop: 15, paddingLeft: '24px' }}
        >
          {formatMessage({
            defaultMessage:
              "Client's account will be charged for this session!",
          })}
        </p>
      );
    }
    if (
      appointment.status === 'absent' &&
      appointment.payment_method === 'inkblot' &&
      !appointment.paid_yn
    ) {
      return (
        <p
          className="error-text"
          style={{ fontSize: 16, marginTop: 15, paddingLeft: '24px' }}
        >
          {formatMessage({
            defaultMessage:
              'Client will be charged the full session fee (regardless of the session duration).',
          })}
        </p>
      );
    }
    return <p />;
  };

  renderStatus = () => {
    const { intl } = this.props;

    const statuses = [
      {
        id: 1,
        value: 'absent',
        primaryText: intl.formatMessage({ defaultMessage: 'Absent' }),
      },
      {
        id: 2,
        value: 'canceled',
        primaryText: intl.formatMessage({ defaultMessage: 'Canceled' }),
      },
      {
        id: 3,
        value: 'completed',
        primaryText: intl.formatMessage({ defaultMessage: 'Completed' }),
      },
      {
        id: 4,
        value: 'failed',
        primaryText: intl.formatMessage({ defaultMessage: 'Failed' }),
      },
      {
        id: 5,
        value: 'pending',
        primaryText: intl.formatMessage({ defaultMessage: 'Pending' }),
        disabled: true,
      },
    ];
    return (
      <DropDownMenu
        value={
          this.state.appointment.status === 'scheduled'
            ? 'pending'
            : this.state.appointment.status
        }
        onChange={(event, i, value) =>
          this.setState(prevState => ({
            appointment: { ...prevState.appointment, status: value },
          }))
        }
      >
        {_.map(statuses, ({ id, value, primaryText, disabled }) => (
          <MenuItem
            key={id}
            value={value}
            primaryText={primaryText}
            disabled={disabled}
          />
        ))}
      </DropDownMenu>
    );
  };

  render() {
    const { open, toggleModal, intl } = this.props;
    const { loading } = this.state;
    const { formatMessage } = intl;

    return (
      <GlobalDialog
        contentClassName="detail-modal"
        bodyClassName="dialog-body"
        modal
        open={open}
        handleClose={toggleModal}
      >
        <div style={{ marginLeft: '20px' }}>
          <GlobalTitle
            title={formatMessage({
              defaultMessage: 'Complete Session',
            })}
          />
        </div>
        <p style={{ marginLeft: '20px' }}>
          {formatMessage({
            defaultMessage:
              'Please set a status for this session before marking it complete. You will not be able to rejoin the session once it is marked complete.',
          })}
        </p>
        {this.renderStatus()}
        {this.renderPaymentWarning()}
        <TextField
          name="feedback"
          floatingLabelText={formatMessage({
            defaultMessage: 'Feedback',
          })}
          floatingLabelFixed
          multiLine
          value={this.state.appointment.feedback}
          rows={4}
          style={{ paddingLeft: '24px' }}
          rowsMax={4}
          onChange={this.handleFeedbackChange}
        />
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          {loading ? (
            <SvgIcon style={{ width: 32, height: 32 }} color="#aaaaaa">
              <Spinner />
            </SvgIcon>
          ) : (
            <FlatButton
              key={2}
              label={formatMessage({
                defaultMessage: 'Complete Session',
              })}
              style={{ marginLeft: '10px' }}
              onClick={() => {
                this.submitStatus();
              }}
              className="flat-button-green"
            />
          )}
        </div>
        <Close onClick={toggleModal} className="close-button-modal" />
      </GlobalDialog>
    );
  }
}

CompleteSessionModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  disconnect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  appointment: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
  const appointment = _.get(state, 'session.appointment');
  return {
    appointment,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    updateAppointment,
  })(CompleteSessionModal),
);
