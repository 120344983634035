import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const Checkbox = ({
  input, options, style, meta, disabled,
}) => {
  const { name, value, onChange } = input;
  const { touched, error } = meta;

  const handleChange = (event, checkboxValue) => {
    const newValue = [...value];
    if (event.target.checked) {
      newValue.push(checkboxValue);
    } else {
      newValue.splice(newValue.indexOf(checkboxValue), 1);
    }

    return onChange(newValue);
  };

  return (
    <div style={{ position: 'relative' }}>
      {_.map(options, (option, i) => (
        <div key={i} className="checkbox" style={style}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              name={`${name}[${i}]`}
              value={option.value}
              checked={value.indexOf(option.value) !== -1}
              onChange={event => handleChange(event, option.value)}
              disabled={disabled}
              style={disabled ? { cursor: 'not-allowed' } : {}}
            />
            <div className="checkbox-svg" />
          </div>
          <p
            className={
              value.indexOf(option.value) !== -1 ? 'label-selected' : null
            }
            style={disabled ? { color: '#aaaaaa' } : {}}
          >
            {option.label}
          </p>
        </div>
      ))}
      {touched && error && <p className="onboarding-error">{error}</p>}
    </div>
  );
};

Checkbox.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  style: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  input: {},
  options: [],
  style: {},
  meta: {},
  disabled: false,
};

export default Checkbox;
