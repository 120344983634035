import { combineReducers } from 'redux';
import { createReducers } from '../../ducksHelpers';
import { failedPaymentsSettings } from './failedPayments';
import { failedPaymentSettings } from './failedPayment';

const settingList = [
  failedPaymentsSettings,
  failedPaymentSettings,
];

const failedPaymentsReducer = combineReducers(createReducers(settingList));

export default failedPaymentsReducer;
