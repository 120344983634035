import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { COUNTRY } from '../../../environment';

const providerStyle = {
  padding: '5px 20px',
  boxShadow: '0 0 20px 1px rgba(146, 180, 255, 0.4)',
  backgroundColor: 'white',
  marginBottom: 10,
  borderRadius: 8,
};

const CATwilioNumber = pin => (
  <FormattedMessage
    defaultMessage="For audio difficulties please call <b>+1 833 461 0480</b> with pin <b>{pin}</b>"
    values={{
      b: content => <b>{content}</b>,
      pin,
    }}
  />
);

const USTwilioNumber = pin => (
  <FormattedMessage
    defaultMessage="For audio difficulties please call <b>+1 833 934 2673</b> with pin <b>{pin}</b>"
    values={{
      b: content => <b>{content}</b>,
      pin,
    }}
  />
);

const TwilioAudio = ({ appointment }) => {
  const pin = _.get(appointment, 'twilio_voice_token');
  return (
    <div style={providerStyle}>
      <div className="alternate-phone">
        <p>{COUNTRY === 'US' ? USTwilioNumber(pin) : CATwilioNumber(pin)}</p>
      </div>
    </div>
  );
};

TwilioAudio.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default TwilioAudio;
