import { combineReducers } from 'redux';
import providerPayoutsReducer from './provider_payouts';
import failedPaymentsReducer from './failed_payments';
import clientSessionNotesReducer from './client_session_notes';

const adminDucksReducer = combineReducers({
  provider_payouts: providerPayoutsReducer,
  failed_payments: failedPaymentsReducer,
  client_session_notes: clientSessionNotesReducer,
});

export default adminDucksReducer;
