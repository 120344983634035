import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GlobalPanel from '../../global/Panel';
import GlobalTitle from '../../global/Title';
import GlobalTabs from '../../global/Tabs';

const SharedFilesTabs = [
  {
    key: 'files_from_clients',
    url: '/shared_files/files_from_clients',
    heading: <FormattedMessage defaultMessage="Files from Clients" />,
  },
  {
    key: 'files_to_clients',
    url: '/shared_files/files_to_clients',
    heading: <FormattedMessage defaultMessage="Files to Clients" />,
  },
  {
    key: 'files_to_all_clients',
    url: '/shared_files/files_to_all_clients',
    heading: <FormattedMessage defaultMessage="Files to All Clients" />,
  },
];

const TopBar = ({ activeTab }) => (
  <div>
    <GlobalPanel style={{ paddingBottom: '50px' }}>
      <GlobalTitle title={<FormattedMessage defaultMessage="Shared Files" />} />
      <GlobalTabs activeTab={activeTab} tabs={SharedFilesTabs} />
    </GlobalPanel>
  </div>
);

TopBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default TopBar;
