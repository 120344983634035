import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PERSONAL_DETAILS = 0;
export const LOCATION_DETAILS = 1;
export const MATCHING_PREFERENCES = 2;
export const AUTOMATIC_PAYOUTS = 3;
export const THANK_YOU = 4;

export const stepper = [
  {
    label: <FormattedMessage defaultMessage="Personal Details" />,
    value: PERSONAL_DETAILS,
  },
  {
    label: <FormattedMessage defaultMessage="Location Details" />,
    value: LOCATION_DETAILS,
  },
  {
    label: <FormattedMessage defaultMessage="Matching Preferences" />,
    value: MATCHING_PREFERENCES,
  },
  {
    label: <FormattedMessage defaultMessage="Payment Information" />,
    value: AUTOMATIC_PAYOUTS,
  },
];

export const titles = [
  {
    value: 'Dr',
    label: <FormattedMessage defaultMessage="Dr" />,
  },
  {
    value: 'Mr',
    label: <FormattedMessage defaultMessage="Mr" />,
  },
  {
    value: 'Ms',
    label: <FormattedMessage defaultMessage="Ms" />,
  },
  {
    value: 'Miss',
    label: <FormattedMessage defaultMessage="Miss" />,
  },
  {
    value: 'Mx',
    label: <FormattedMessage defaultMessage="Mx" />,
  },
];

export const genders = [
  {
    value: 'male',
    label: <FormattedMessage defaultMessage="Male" />,
  },
  {
    value: 'female',
    label: <FormattedMessage defaultMessage="Female" />,
  },
  {
    value: 'custom',
    label: <FormattedMessage defaultMessage="Custom" />,
  },
  {
    value: 'unspecified',
    label: <FormattedMessage defaultMessage="Prefer not to say" />,
  },
];

export const countries = [
  { value: 'CAN', label: 'Canada' },
  { value: 'USA', label: 'United States' },
];

export const provinces = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon Territory' },
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];
