import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fromTherapy from './fromTherapy';

export default ComposedComponent => {
  class SessionWrapper extends Component {
    componentDidMount() {
      if (this.props.match.params.token) {
        fromTherapy(this.props);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  SessionWrapper.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return SessionWrapper;
};
