import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const appointmentDetailsSettings = {
  stateName: 'appointmentDetails',
  dataType: 'object',
  actions: {
    update: 'ducks/billing/UPDATE_APPOINTMENT_DETAILS',
  },
};

// Action Creators
export function updateAppointmentDetails(
  id,
  values,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    appointmentDetailsSettings.actions.update,
    'put',
    `${API_URL}/practice/appointment_details/${id}`,
    { ...values },
    true,
    callback,
    errorCallback,
  );
}
