import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import Side from './SideBar';
import Header from './Header';
import renderAssessments from './renderAssessments';
import { fetchAppointment } from '../../../actions/sessions';
import { THERAPY_URL } from '../../../environment';

class PostSessionAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchAppointment(this.props.match.params.id);
  }

  render() {
    const { postAssessment } = this.props;
    return (
      <div className="row no-gutters" style={{ height: '100%' }}>
        <div className="col-2">
          <Side color="#e6f9f7" />
        </div>
        <div className="col-10" style={{ paddingTop: '40px', paddingLeft: '30px' }}>
          <Header />
          <div style={{ maxWidth: '700px' }}>
            {renderAssessments(postAssessment)}
            <Link to="/dashboard">
              <FlatButton className="flat-button-green " label="complete session" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

PostSessionAssessment.propTypes = {
  fetchAppointment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  postAssessment: PropTypes.array,
};

PostSessionAssessment.defaultProps = {
  postAssessment: [],
};

function mapStateToProps(state) {
  return {
    appointment: _.get(state, 'session.appointment'),
    postAssessment: _.get(state, 'session.appointment.post_assessment'),
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAppointment,
  },
)(PostSessionAssessment);
