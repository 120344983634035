import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const exportClientSessionNotesSettings = {
  stateName: 'exportClientSessionNotes',
  dataType: 'object',
  actions: {
    fetch: 'ducks/client_session_notes/EXPORT_CLIENT_SESSION_NOTES',
  },
};

export function exportClientSessionNotes(
  clientId,
  filters,
  callback = () => {},
  errorCallback = () => {},
) {
  const filterString = Object.keys(filters)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`)
    .join('&');

  return ducksActionCreator(
    exportClientSessionNotesSettings.actions.fetch,
    'get',
    `${API_URL}/admin/documents/export?client_id=${clientId}&${filterString}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
