import React from 'react';
import PropTypes from 'prop-types';
import ClientCard from './partials/UserCard';
import Welcome from './partials/WelcomeCounsellors';

const CounsellorWaiting = (props, state) => {
  let partner = null;
  if (props.appointment &&
    props.appointment.provider_type === 'couples' &&
    props.appointment.couples_appointment_detail) {
    partner = props.appointment.couples_appointment_detail.guest;
  }

  return (
    <div className="row">
      <div className="col-6" style={{ maxWidth: '600px' }}>
        <Welcome appointment={props.appointment} />
      </div>
      <div className="col-6">
        {props.appointment && (
          <ClientCard
            appointment_id={props.match.params.id}
            countdown={state.countdown}
            disabled={state.button_disabled}
            user={props.appointment.client}
            partner={partner}
          />
        )}
      </div>
    </div>
  );
};

CounsellorWaiting.propTypes = {
  client: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  appointment: PropTypes.object,
  location: PropTypes.object.isRequired,
};

CounsellorWaiting.defaultProps = {
  appointment: {},
};

export default CounsellorWaiting;
