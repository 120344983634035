import React from 'react';
import { FormattedMessage } from 'react-intl';
import Down from '../../../images/onboarding/practitioner/down.svg';
import { scrollDown, scrollBarVisible } from './scroll';

function ScrollDown() {
  return scrollBarVisible() ? (
    <div
      className="scroll-down flex-center"
      onClick={scrollDown}
      role="presentation"
    >
      <span>
        <FormattedMessage defaultMessage="scroll" />
      </span>
      <Down style={{ marginTop: '1px' }} />
    </div>
  ) : (
    <noscript />
  );
}

export default ScrollDown;
