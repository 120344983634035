import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';

import RightMessageBubble from './messageBubbles/RightMessageBubble'
import LeftMessageBubble from './messageBubbles/LeftMessageBubble'
import DateWithLine from './messageBubbles/DateWithLine';

class Messages extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.messages.length !== this.props.messages.length) {
      const msgDiv = document.getElementById('sidebar-message-list');
      msgDiv.scrollTop = msgDiv.scrollHeight;
    }
  }

  render() {
    const { messages, timezone } = this.props;
    return (
      <div id="sidebar-message-list" className="sidebar-message-list">
        {messages && messages.map((singleMessage, index, originalArr) => {
            // These two logics below are for finding the first message element of each day
            // 1. check if it is a first element among messages from the first day == >index == 0
            // 2. check if it is a first element among messages from the same day  ==> (originalArr[Number(index) - 1] ~~ format( 'MMMM DD, YYYY', ))
            // - can be determined by comparing current element's timestamp is equal to previous element's timestamp
            if (
              index === 0 ||
              (originalArr[Number(index) - 1] &&
                !moment.tz(singleMessage.timestamp, timezone).isSame(moment.tz(originalArr[Number(index) - 1].timestamp, timezone), "day") 
              ))
                {
              return (
                <div>
                  <DateWithLine
                    timestamp={moment(singleMessage.timestamp).tz(timezone)}
                  />
                  {singleMessage.me === true ? (
                    // if a single message is a first message among messages from the same day &&
                    // if it is from me then print right bubble w/ dateline
                    <RightMessageBubble
                      timestamp={moment(singleMessage.timestamp).tz(timezone)}
                      body={singleMessage.body}
                    />
                  ) : (
                    // if a single message is a first message among messages from the same day &&
                    // if it is from me then print left bubble w/ dateline
                    <LeftMessageBubble
                      timestamp={moment(singleMessage.timestamp).tz(timezone)}
                      body={singleMessage.body}
                    />
                  )}
                </div>
              );
            }
            // if a single message is NOT a first message among messages from the same day
            if (singleMessage.me === true) {
              // if a single message is NOT a first message among messages from the same day &&
              // if it is from me then print right bubble wo/ dateline
              return (
                <RightMessageBubble
                  timestamp={moment(singleMessage.timestamp).tz(timezone)}
                  body={singleMessage.body}
                />
              );
            }
            // if a single message is NOT a first message among messages from the same day &&
            // if it is from me then print left bubble wo/ dateline
            return (
              <LeftMessageBubble
                timestamp={moment(singleMessage.timestamp).tz(timezone)}
                body={singleMessage.body}
              />
            );
          })}
      </div>
    );
  }
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default Messages;
