import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const inHouseCompletedAppointmentsSettings = {
  stateName: 'inHouseCompletedAppointments',
  dataType: 'array',
  actions: {
    fetch: 'ducks/billing/FETCH_IN_HOUSE_COMPLETED_APPOINTMENTS',
  },
};

// Action Creators
export function fetchInHouseCompletedAppointments(callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    inHouseCompletedAppointmentsSettings.actions.fetch,
    'get',
    `${API_URL}/practice/appointments?inhouse_completed=true`,
    {},
    true,
    callback,
    errorCallback,
  );
}
