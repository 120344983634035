/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Side from './partials/SideBar';
import CounsellorWaiting from './CounsellorWaiting';
import { fetchAppointment } from '../../../actions/sessions';
import {
  updateUserAppointment,
  fetchUserAppointment,
} from '../../../ducks/sessions/userAppointment';

class WaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button_disabled: true,
    };
  }

  componentDidMount() {
    this._ismounted = true;
    this.props.fetchAppointment(this.props.match.params.id, response => {
      const now = moment(new Date());
      const then = moment.utc(response.data.start_date);
      const ms = moment(then, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(now, 'DD/MM/YYYY HH:mm:ss'),
      );
      this.props.updateUserAppointment(
        response.data.id,
        { waiting_yn: true },
        () => {
          if (this._ismounted) {
            this.countdownTimer(ms);
          }
        },
      );
    });
  }

  componentWillUnmount() {
    this._ismounted = false;
    clearInterval(this.intervalID);
  }

  countdownTimer = time => {
    let newTime = time;
    const interval = 1000;
    const oneHour = 3600000;
    const threeMinutes = 180000;
    this.intervalID = setInterval(() => {
      if (newTime - interval > 0) newTime -= interval;
      else newTime = 0;

      const d = moment.duration(newTime);
      const s = Math.floor(d.asHours()) + moment.utc(newTime).format(':mm:ss');
      this.setState(() => ({
        countdown: s,
      }));

      // checks if its between 3 minutes and 0
      if (
        (newTime >= -threeMinutes && newTime <= 0) ||
        this.otherUserWaiting()
      ) {
        clearInterval(this.intervalID);
        this.setState(() => ({
          countdown: (
            <FormattedMessage defaultMessage="Your Session is Ready" />
          ),
          button_disabled: false,
        }));
      }

      // checks if its been over 1 hours
      if (newTime < -oneHour) {
        clearInterval(this.intervalID);
        this.setState(() => ({
          countdown: (
            <FormattedMessage defaultMessage="This session has expired" />
          ),
          button_disabled: true,
        }));
      }
    }, interval);
  };

  otherUserWaiting = () => {
    const { userAppointment } = this.props;

    this.props.fetchUserAppointment(
      this.props.match.params.id,
      '?other_user=true',
    );

    return userAppointment ? userAppointment.waiting_yn : false;
  };

  render() {
    return (
      <div className="row no-gutters" style={{ height: '100%' }}>
        <div className="col-2">
          <Side color="#e6f9f7" />
        </div>
        <div
          className="col-10"
          style={{ paddingTop: '100px', paddingLeft: '30px' }}
        >
          {CounsellorWaiting(this.props, this.state)}
        </div>
      </div>
    );
  }
}

WaitingRoom.propTypes = {
  fetchAppointment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  fetchUserAppointment: PropTypes.func.isRequired,
  updateUserAppointment: PropTypes.func.isRequired,
  userAppointment: PropTypes.object,
};

WaitingRoom.defaultProps = {
  userAppointment: {},
};

function mapStateToProps(state) {
  const assessments = _.get(state, 'session.appointment.pre_assessment');
  const initalAssessmentValues = _.map(assessments, o => ({ [o.id]: o.value }));
  const initialValues = Object.assign({}, ...initalAssessmentValues);
  const appointment = _.get(state, 'session.appointment');
  let url;

  if (appointment) {
    if (appointment.modality === 'In Person') {
      url = 'in_person';
    } else if (appointment.modality === 'Video') {
      url = 'video_call';
    } else if (appointment.modality === 'Voice') {
      url = 'voice_call';
    }
  }

  return {
    me: state.user,
    appointment,
    preAssessment: _.get(state, 'session.appointment.pre_assessment'),
    initialValues,
    userAppointment: state.ducks.sessions.userAppointment,
    url,
  };
}

export default connect(mapStateToProps, {
  fetchAppointment,
  fetchUserAppointment,
  updateUserAppointment,
})(WaitingRoom);
