import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Snackbar } from 'material-ui';
import * as utils from '../practitioner_video/utils';
import Panel from '../panel';
import {
  fetchAppointment,
  fetchAppointmentNotes,
  fetchSessionAnalytics,
  updateAppointmentSession,
} from '../../../actions/sessions';
import { fetchClient } from '../../../actions/clients';
import { fetchUser } from '../../../actions/user';
import { updateAppointment } from '../../../ducks/sessions/appointment';
import { fetchTodayAppointments } from '../../../ducks/dashboard/todayAppointments';

import { fetchDocuments } from '../../../actions/process';
import Notes from './Notes';
import CompleteSessionModal from '../practitioner_video/CompleteSessionModal';

class InPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown: 'Loading Time',
      snackbarOpen: false,
      completeSession: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchAppointment(match.params.id, apptResponse => {
      const clientID = apptResponse.data.client.id;

      this.countdownTimer();

      this.props.fetchUser(userRes => {
        const { me } = this.props;

        // appointment_id belonging to the current user
        const appointment_session_id = _.find(
          apptResponse.data.appointment_sessions,
          {
            user_id: me && me.id,
          },
        ).id;

        // lets us know the user joined
        this.props.updateAppointmentSession(appointment_session_id, {
          joined_yn: true,
          source: 'in_person'
        });

        this.props.updateAppointment(match.params.id, { status: 'pending' });

        // only fetch these if you are NOT a client
        if (utils.determineIfProvider(userRes.data.roles)) {
          this.props.fetchClient(clientID);
          this.props.fetchSessionAnalytics(clientID);
          this.props.fetchAppointmentNotes(clientID);
        }
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.disconnectAll();
  }

  disconnectAll = action => {
    clearInterval(this.intervalID);

    if (action === 'completed') {
      utils.setCompleted(this);
    } else if (action === 'failed') {
      utils.setFailed(this);
    }

    utils.pushThem(this);
  };

  // countsdown the time remaining in a session
  countdownTimer = () => {
    const interval = 1000;
    const oneHour = -60;
    const endTime = moment(this.props.appointment.end_date);

    this.intervalID = setInterval(() => {
      const timeLeft = endTime.diff(moment(), 'minutes');
      const hours = Math.floor(Math.abs(timeLeft) / 60);
      const minutes = Math.abs(timeLeft) % 60;

      if (timeLeft >= 0) {
        const countdown =
          minutes < 10 ? `${hours}:0${minutes}` : `${hours}:${minutes}`;
        this.setState({
          countdown,
        });
      } else if (timeLeft < 0) {
        const countdown =
          minutes < 10 ? `-${hours}:0${minutes}` : `-${hours}:${minutes}`;
        this.setState({
          countdown,
          snackbarOpen: true,
        });
      }

      if (timeLeft < oneHour) {
        clearInterval(this.intervalID);
        this.disconnectAll();
      }
    }, interval);
  };

  toggle = modal => {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  };

  render() {
    const {
      provider_yn,
      appointmentNote,
      clientData,
      allAppointmentNotes,
    } = this.props;

    return (
      <div className="row">
        <div
          style={{ paddingTop: '20px' }}
          className="col-lg-7 col-md-12 col-sm-12"
        >
          <Notes
            countdown={this.state.countdown}
            appointmentNoteID={appointmentNote && appointmentNote.id}
            toggle={() => this.toggle('completeSession')}
            updateNoteText={this.updateNoteText}
          />
        </div>
        <div
          className="col-lg-5 col-md-12 col-sm-12"
          style={{ minHeight: '800px', paddingRight: 0 }}
        >
          <Panel
            allAppointmentNotes={allAppointmentNotes}
            clientData={clientData}
            matchParamsID={this.props.match.params.id}
            provider_yn={provider_yn}
          />
        </div>
        <Snackbar
          bodyStyle={{ background: '#92b4ff' }}
          className="vidyo-snack"
          onRequestClose={() => false}
          open={this.state.snackbarOpen}
          message="This session has expired and will close in 1 hour"
        />
        <CompleteSessionModal
          open={this.state.completeSession}
          toggleModal={() => this.toggle('completeSession')}
          disconnect={this.disconnectAll}
          providerYn={provider_yn}
        />
      </div>
    );
  }
}

InPerson.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchAppointment: PropTypes.func.isRequired,
  fetchClient: PropTypes.func.isRequired,
  fetchAppointmentNotes: PropTypes.func.isRequired,
  fetchSessionAnalytics: PropTypes.func.isRequired,
  updateAppointmentSession: PropTypes.func.isRequired,
  provider_yn: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  appointment: PropTypes.object,
  appointmentNote: PropTypes.object,
  clientData: PropTypes.object,
  me: PropTypes.object,
  allAppointmentNotes: PropTypes.array,
};

InPerson.defaultProps = {
  appointment: {},
  appointmentNote: {},
  allAppointmentNotes: [],
  clientData: {},
  me: {},
};

function mapStateToProps(state) {
  const roles = _.get(state.user, 'roles');
  const appointment = _.get(state, 'session.appointment');
  const counsellor = _.get(appointment, 'counsellor');
  const client = _.get(appointment, 'client');
  const appointmentNote = _.find(appointment && appointment.appointment_notes, {
    user_id: state.user.id,
  });
  return {
    appointment,
    appointmentNote,
    roles,
    me: state.user,
    provider_yn: utils.determineIfProvider(roles),
    counsellorName: utils.nameParser(counsellor),
    clientName: utils.nameParser(client),
    clientID: client && client.id,
    clientData: client,
    allAppointmentNotes: state.session.appointment_notes,
  };
}

export default connect(mapStateToProps, {
  updateAppointmentSession,
  fetchAppointment,
  fetchAppointmentNotes,
  fetchClient,
  fetchUser,
  fetchTodayAppointments,
  fetchSessionAnalytics,
  updateAppointment,
  fetchDocuments,
})(
  reduxForm({
    form: 'SessionForm',
    enableReinitialize: true,
  })(InPerson),
);
