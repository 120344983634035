import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const FlagButtons = ({ input, meta, options }) => {
  const { name, value, onChange } = input;
  const { touched, error } = meta;

  return (
    <div style={{ position: 'relative' }}>
      {_.map(options, (option, i) => (
        <div className="flag-button" key={i}>
          <input
            type="checkbox"
            name={`${name}[${i}]`}
            value={option.value}
            checked={value.indexOf(option.value) !== -1}
            onChange={event => {
              const newValue = [...value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }

              return onChange(newValue);
            }}
          />
          <div className="flag-box">
            <span className="flag-label">{option.label}</span>
          </div>
        </div>
      ))}
      {touched && error && <p className="onboarding-error">{error}</p>}
    </div>
  );
};

FlagButtons.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array.isRequired,
};

FlagButtons.defaultProps = {
  input: {},
  meta: {},
};

export default FlagButtons;
