import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Snackbar } from 'material-ui';
import { connect } from 'react-redux';

import localeMessage from '../localeMessage';

const bodyStyle = {
  backgroundColor: '#ffffff',
  boxShadow: '0 0 50px 0 rgba(40, 71, 140, 0.2)',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
};

const contentStyle = {
  color: '#19336a',
  fontFamily: 'Barlow',
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: '0.24px',
  paddingLeft: '20px',
  paddingRight: '20px',
};

class Countdown extends Component {
  state = { countdown: 'Loading...', timer: true, snackbarOpen: false };

  componentDidMount() {
    const now = moment(new Date());
    const end = moment.utc(_.get(this.props.appointment, 'end_date'));
    const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(now, 'DD/MM/YYYY HH:mm:ss'),
    );
    this.countdownTimer(ms);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  toggleTimer = () => {
    this.setState(prevState => ({
      timer: !prevState.timer,
    }));
  };

  // countsdown the time remaining in a session
  countdownTimer = time => {
    let newTime = time;
    const interval = 1000;
    const oneHour = 3600000;
    const endTime = moment(_.get(this.props.appointment, 'end_date'));
    this.intervalID = setInterval(() => {
      newTime -= interval;
      const d = moment.duration(newTime);
      const s = Math.floor(d.asHours()) + moment.utc(newTime).format(':mm');

      this.setState(() => ({
        countdown: s,
      }));

      // When the session has expired, user can still remain in the session
      if (newTime < 0) {
        const timeOver = moment().diff(endTime, 'minutes');
        const negativeRemainder =
          timeOver >= 10 ? `-0:${timeOver}` : `-0:0${timeOver}`;
        this.setState(() => ({
          snackbarOpen: true,
          countdown: negativeRemainder,
        }));
        this.props.sessionOver();
      }
      // Kick users out if they remain after the session has been expired for an hour
      if (newTime < -oneHour) {
        clearInterval(this.intervalID);
      }
    }, interval);
  };

  render() {
    const { locale } = this.props;
    return (
      <div className="bottom-bar-timer">
        {this.state.timer ? (
          <p className={this.props.connected ? 'connected-time' : 'time'}>
            {this.state.countdown}
          </p>
        ) : (
          <p className="time">- -:- -</p>
        )}
        <p role="presentation" className="hide-time" onClick={this.toggleTimer}>
          {this.state.timer
            ? localeMessage('hideTime', locale)
            : localeMessage('showTime', locale)}
        </p>
        <Snackbar
          bodyStyle={bodyStyle}
          contentStyle={contentStyle}
          className="bottom-bar-snack"
          onRequestClose={() => false}
          open={this.state.snackbarOpen}
          message={localeMessage('hasExpired', locale)}
        />
      </div>
    );
  }
}

Countdown.propTypes = {
  appointment: PropTypes.object.isRequired,
  sessionOver: PropTypes.func.isRequired,
  connected: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(Countdown);
