import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import Pagination from '@material-ui/lab/Pagination';

import TopBar from './partials/topBar';
import ClientSelect from '../global/ClientSelect';
import GlobalPanel from '../global/Panel';
import DataLoadingSpinner from '../global/DataLoadingSpinner';
import { fetchPaginatedFileShares } from '../../actions/resources';

const propTypes = {
  fetchPaginatedFileShares: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

class FilesFromClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      pagination: {},
      loading: false,
      clientId: null,
      sortBy: 'date-newest',
    };
  }

  componentDidMount() {
    this.fetchList(1);
  }

  fetchList = page => {
    const { clientId, sortBy } = this.state;
    this.setState({ loading: true });
    this.props.fetchPaginatedFileShares(
      page,
      clientId,
      sortBy,
      (files, error) => {
        this.setState({ loading: false });
        if (!error) {
          this.setState({
            files: files.data,
            pagination: files.pagination_info,
          });
        }
      },
    );
  };

  handleClientSelectChange = clientId => {
    this.setState({ clientId }, () => this.fetchList(1));
  };

  handleSortChange = (event, i, value) => {
    this.setState({ sortBy: value }, () => this.fetchList(1));
  };

  renderRows = () => {
    const { files } = this.state;
    const { formatDate } = this.props.intl;

    return files.map(file => (
      <TableRow key={file.id}>
        <TableRowColumn>
          {formatDate(file.created_at, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: localStorage.getItem('current-timezone'),
          })}
        </TableRowColumn>
        <TableRowColumn className="process-name">{file.user}</TableRowColumn>
        <TableRowColumn>
          {file.file_name.replace(/__([a-zA-Z0-9])+\./, '.')}
        </TableRowColumn>
        <TableRowColumn>
          <FlatButton
            href={file.resource}
            className="flat-button-baby-blue process-action-button"
            label={<FormattedMessage defaultMessage="View" />}
            download
            target="_blank"
          />
        </TableRowColumn>
      </TableRow>
    ));
  };

  renderTable = () => {
    const { pagination } = this.state;

    return (
      <div className="module-table">
        <Table
          fixedHeader={false}
          style={{ marginLeft: 0, tableLayout: 'auto' }}
          className="appointment-table"
          selectable={false}
        >
          <TableHeader
            className="remove-border"
            displaySelectAll={false}
            adjustForCheckbox={false}
          >
            <TableRow className="remove-border" selectable={false}>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Date" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Client" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="File" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Action" />
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>{this.renderRows()}</TableBody>
        </Table>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        >
          {pagination && pagination.total && pagination.total_items ? (
            <Pagination
            shape="rounded"
              count={pagination.total}
              page={pagination.current}
              onChange={(_, page) => this.fetchList(page)}
            />
          ) : (
            <noscript />
          )}
        </div>
      </div>
    );
  };

  renderFilters = () => {
    const { formatMessage } = this.props.intl;

    return (
      <div
        style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 30 }}
      >
        <div style={{ width: 250 }}>
          <ClientSelect onChange={this.handleClientSelectChange} />
        </div>
        <DropDownMenu
          value={this.state.sortBy}
          onChange={this.handleSortChange}
        >
          <MenuItem
            value="date-newest"
            primaryText={formatMessage({
              defaultMessage: 'Date - Newest First',
            })}
          />
          <MenuItem
            value="date-oldest"
            primaryText={formatMessage({
              defaultMessage: 'Date - Oldest First',
            })}
          />
        </DropDownMenu>
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <TopBar activeTab="files_from_clients" />
        <GlobalPanel>
          {this.renderFilters()}
          {loading ? <DataLoadingSpinner /> : this.renderTable()}
        </GlobalPanel>
      </div>
    );
  }
}

FilesFromClients.propTypes = propTypes;

export default injectIntl(
  connect(null, { fetchPaginatedFileShares })(FilesFromClients),
);
