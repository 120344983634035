import { API_URL } from '../../../environment';
import { ducksActionCreator } from '../../ducksHelpers';

export const failedPaymentSettings = {
  stateName: 'failedPayment',
  dataType: 'object',
  actions: {
    fetch: 'ducks/failed_payment/FETCH_FAILED_PAYMENT',
    update: 'ducks/failed_payment/UPDATE_FAILED_PAYMENT',
  },
};

export function fetchFailedPayment(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    failedPaymentSettings.actions.fetch,
    'get',
    `${API_URL}/admin/card_payment_retries/${id}`,
    {},
    true,
    callback,
    errorCallback,
  );
}

export function cancelFailedPayment(id, callback = () => {}, errorCallback = () => {}) {
  return ducksActionCreator(
    failedPaymentSettings.actions.update,
    'put',
    `${API_URL}/admin/card_payment_retries/${id}`,
    { update_type: 'cancel' },
    true,
    callback,
    errorCallback,
  );
}
