import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import BottomBar from '../BottomBar';
import FileUpload from '../../global/FileUpload';
import { uploadDocument } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class CertificateUpload extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem uploading your documents." />
    );

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Uploading documents..." />,
    );
    Promise.all([...this.uploadDocuments(values.degree, 'degree')])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'required documents',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  // Return an array of promises the same size as the number of documents there are
  uploadDocuments = (documents, type) => {
    let promises = [];

    if (documents && documents.length > 0) {
      promises = documents.map(document => {
        const params = new FormData();
        params.append('document', document);
        params.append('document_type', type);

        return this.props.uploadDocument(params);
      });

      logEvent('upload required document', { document: type });
    }

    return promises;
  };

  fileSizeError = () =>
    this.props.openErrorDialog(
      <FormattedMessage defaultMessage="There was a problem with a file you selected. Please check to make sure your files meet the max size limit" />,
    );

  render() {
    const { handleSubmit } = this.props;

    const email = this.props.intl.formatMessage({
      defaultMessage: 'practitionernetwork@inkblottherapy.com',
    });

    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="page-title" style={{ marginBottom: '20px' }}>
            <FormattedMessage
              tagName="h1"
              defaultMessage="Required Documents"
            />
          </div>
          <Field
            name="degree"
            component={FileUpload}
            label={
              <FormattedMessage defaultMessage="Highest relevant degree" />
            }
            multiple
            onDropRejected={this.fileSizeError}
          />

          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <BottomBar {...this.props} />
        </form>
        <FormattedMessage
          defaultMessage="If you have difficulty uploading any of these documents, please email them to {link}"
          values={{
            link: <a href={`mailto:${email}`}>{email}</a>,
          }}
        />
      </div>
    );
  }
}

CertificateUpload.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};

  if (!values.degree)
    errors.degree = <FormattedMessage defaultMessage="Required" />;

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(connect(null, { uploadDocument })(CertificateUpload)),
);
