import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import GlobalPanel from '../../global/Panel';
import NotesBox from '../panel/NotesBox';

const Notes = ({
  countdown, appointmentNoteID, toggle,
}) => (
  <GlobalPanel style={{ padding: '20px 30px 30px 30px' }}>
    <div className="countdown">
      <h4>TIME REMAINING</h4>
      <h4>{countdown}</h4>
    </div>
    { appointmentNoteID && <NotesBox appointmentNoteID={appointmentNoteID} /> }
    <div style={{ textAlign: 'right' }}>
      <FlatButton
        className="flat-button-green"
        onClick={toggle}
        label="Complete Session"
        style={{ marginTop: '20px' }}
      />
    </div>
  </GlobalPanel>
);

Notes.propTypes = {
  countdown: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  appointmentNoteID: PropTypes.number,
};

Notes.defaultProps = {
  appointmentNoteID: null,
};

export default Notes;
