import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tabs, Tab } from 'material-ui/Tabs';
import { connect } from 'react-redux';

import localeMessage from '../localeMessage';
import { tabs } from './tabs';

const buttonStyle = {
  borderBottom: '1px solid #dce5f7',
  color: 'rgba(15, 32, 69, 0.75)',
  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '-0.07em',
};
const activeButtonStyle = {
  ...buttonStyle,
  color: '#2e5fca',
};
const inkBarStyle = { background: '#2e5fca', height: '3px', zIndex: '2' };

const TabContainer = ({ activeTab, handleChange, isCouplesGuest, locale }) => {
  const filteredTabs = isCouplesGuest
    ? tabs.filter(tab => tab.name !== 'Notes')
    : tabs;

  return (
    <div>
      <Tabs inkBarStyle={inkBarStyle}>
        {_.map(filteredTabs, ({ index, name, inactiveIcon, activeIcon }) => (
          <Tab
            buttonStyle={activeTab === index ? activeButtonStyle : buttonStyle}
            label={localeMessage(name, locale)}
            key={index}
            icon={activeTab === index ? activeIcon : inactiveIcon}
            onActive={handleChange}
            disableTouchRipple
          />
        ))}
      </Tabs>
    </div>
  );
};

TabContainer.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  isCouplesGuest: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(TabContainer);
