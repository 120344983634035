import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import NotesBox from './NotesBox';
import Demographics from './Demographics';
import PreviousNotes from './PreviousNotes';
import AvailableServices from './AvailableServices';
import Tabs from './NavigationTabs';
import { fetchAppointment } from '../../../actions/sessions';

class Panel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tab: 0,
    };
  }

  onChangeTab = values => {
    this.props.fetchAppointment(this.props.matchParamsID);
    this.setState({ tab: values.props.index });
  };

  initializeTabs = (modality, sessionType) => {
    // online
    if (modality === 'Video' || modality === 'Voice') {
      if (sessionType === 'couples') {
        return {
          notes: {
            name: <FormattedMessage defaultMessage="Notes" />,
            index: 0,
          },
          previous_notes: {
            name: <FormattedMessage defaultMessage="Previous Notes" />,
            index: 1,
          },
        };
      }
      return {
        notes: { name: 'Notes', index: 0 },
        demographics: {
          name: <FormattedMessage defaultMessage="Client Info" />,
          index: 1,
        },
        previous_notes: {
          name: <FormattedMessage defaultMessage="Previous Notes" />,
          index: 2,
        },
        workplace_benefits: {
          name: <FormattedMessage defaultMessage="Available Services" />,
          index: 3,
        },
      };
      // in-person
    }
    if (sessionType === 'couples') {
      return {
        previous_notes: {
          name: <FormattedMessage defaultMessage="Previous Notes" />,
          index: 0,
        },
      };
    }
    return {
      demographics: {
        name: <FormattedMessage defaultMessage="Demographics" />,
        index: 0,
      },
      previous_notes: {
        name: <FormattedMessage defaultMessage="Previous Notes" />,
        index: 1,
      },
      workplace_benefits: {
        name: <FormattedMessage defaultMessage="Available Services" />,
        index: 2,
      },
    };
  };

  renderCurrentTab = (modality, sessionType) => {
    const { clientData, allAppointmentNotes, appointmentNoteID } = this.props;
    const { tab } = this.state;

    if (modality === 'Video' || modality === 'Voice') {
      // online
      if (sessionType === 'couples') {
        // couples video
        switch (tab) {
          case 0:
            return (
              appointmentNoteID && (
                <NotesBox appointmentNoteID={appointmentNoteID} />
              )
            );
          case 1:
            return <PreviousNotes allAppointmentNotes={allAppointmentNotes} />;
          default:
            return <div />;
        }
      } else {
        // individual video
        switch (tab) {
          case 0:
            return (
              appointmentNoteID && (
                <NotesBox appointmentNoteID={appointmentNoteID} />
              )
            );
          case 1:
            return <Demographics clientData={clientData} />;
          case 2:
            return <PreviousNotes allAppointmentNotes={allAppointmentNotes} />;
          case 3:
            return <AvailableServices clientData={clientData} />;
          default:
            return <div />;
        }
      }
    } else {
      // in person
      if (sessionType === 'couples') {
        // couples
        switch (tab) {
          case 0:
            return <PreviousNotes allAppointmentNotes={allAppointmentNotes} />;
          default:
            return <div />;
        }
      }

      // individual
      switch (tab) {
        case 0:
          return <Demographics clientData={clientData} />;
        case 1:
          return <PreviousNotes allAppointmentNotes={allAppointmentNotes} />;
        case 2:
          return <AvailableServices clientData={clientData} />;
        default:
          return <div />;
      }
    }
  };

  render() {
    const { appointment, appointmentNoteID } = this.props;

    return appointment ? (
      <div
        style={{
          padding: '40px 20px',
          boxShadow: '0 0 20px 1px rgba(146, 180, 255, 0.4)',
          backgroundColor: 'white',
          borderRadius: 8,
          minHeight: 600,
        }}
      >
        <Tabs
          tabs={this.initializeTabs(
            appointment.modality,
            appointment.provider_type,
          )}
          onChange={this.onChangeTab}
        />
        <div style={{ padding: '30px 10px 10px 10px' }}>
          {this.renderCurrentTab(
            appointment.modality,
            appointment.provider_type,
          )}
        </div>
      </div>
    ) : (
      // this is what you see if you are the a client
      appointmentNoteID && <NotesBox appointmentNoteID={appointmentNoteID} />
    );
  }
}

Panel.propTypes = {
  fetchAppointment: PropTypes.func.isRequired,
  matchParamsID: PropTypes.string.isRequired,
  allAppointmentNotes: PropTypes.array,
  clientData: PropTypes.object,
  appointment: PropTypes.object,
  appointmentNoteID: PropTypes.number,
};

Panel.defaultProps = {
  allAppointmentNotes: [],
  clientData: {},
  appointment: null,
  appointmentNoteID: null,
};

function mapStateToProps(state) {
  const appointment = _.get(state, 'session.appointment');
  const appointmentNote = _.find(appointment && appointment.appointment_notes, {
    user_id: state.user.id,
  });

  return {
    appointment,
    appointmentNoteID: appointmentNote ? appointmentNote.id : null,
  };
}

export default connect(mapStateToProps, {
  fetchAppointment,
})(Panel);
