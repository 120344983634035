import { combineReducers } from 'redux';
import { createReducers } from '../../ducksHelpers';
import { providerPayoutsSettings } from './providerPayouts';
import { providerPayoutSettings } from './providerPayout';
import { providerPayoutSourceSettings } from './providerPayoutSource';

const settingList = [
  providerPayoutsSettings,
  providerPayoutSettings,
  providerPayoutSourceSettings,
];

const providerPayoutsReducer = combineReducers(createReducers(settingList));

export default providerPayoutsReducer;
