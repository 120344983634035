import React from 'react';

const Header = () => {
  return (
    <div style={{ maxWidth: '550px' }}>
      <h1 className="post-session-title" style={{ marginBottom: '30px' }}>
        Post-Session Assessment
      </h1>
      <span style={{ marginBottom: '30px' }} className="line" />
      <p style={{ marginBottom: '40px' }} className="post-session-subtitle">
        Please rate this session by placing a mark on the line nearest to the
        description that best fits your experience. This component is needed to
        optimize your care.
      </p>
    </div>
  );
};

export default Header;
