import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import 'react-select/dist/react-select.css';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  isFetchCompleted: PropTypes.bool,
  readonly: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

class FormSelector extends Component {
  constructor(props) {
    super(props);
    this.doneUpdateInitialData = false;
    this.state = { readonlyText: '' };
  }

  componentDidUpdate() {
    const { readonly, isFetchCompleted } = this.props;

    if (readonly && isFetchCompleted && !this.doneUpdateInitialData) {
      this.doneUpdateInitialData = true;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ readonlyText: this.findLabelByValue() });
    }
  }

  findLabelByValue = () => {
    const {
      input: { value },
      options,
    } = this.props;
    const index = options.findIndex(
      option => String(option.value) === String(value),
    );
    return index < 0 ? '' : options[index].label;
  };

  handleChange = value => {
    const {
      input: { onChange },
    } = this.props;
    onChange(value);
  };

  render() {
    const { options, meta, readonly, input, intl } = this.props;
    const { formatMessage } = intl;
    const { readonlyText } = this.state;

    if (readonly) {
      return (
        <div
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            height: 48,
            paddingTop: 10,
          }}
        >
          {readonlyText}
        </div>
      );
    }

    return (
      <div className="form-selector">
        <Select
          options={options}
          simpleValue
          clearable
          value={input.value}
          onChange={this.handleChange}
          searchable
          placeholder={formatMessage({ defaultMessage: 'Search ...' })}
          noResultsText={formatMessage({ defaultMessage: 'No results found' })}
        />
        <div className="error-text">{meta.touched ? meta.error : ''}</div>
      </div>
    );
  }
}

FormSelector.propTypes = propTypes;

FormSelector.defaultProps = {
  options: [],
  isFetchCompleted: false,
  readonly: false,
};

export default injectIntl(FormSelector);
